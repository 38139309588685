<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>
<!-- <app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup> -->
<!-- <app-confirmation-popup style="display: none;" #popup (imgStatus)="imgStatus($event)" (status)="status($event)">
</app-confirmation-popup> -->
<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode=0;back()"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{'master.Contacts.heading' | translate}}
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">

                                <button class="btn btn-light mr-1" (click)="clear()" type="button"
                                   >
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}</button>&nbsp;
                                <button
                                    class="btn btn-success pull-right mr-2" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}</button>

                                <!-- <button (click)="showAttachement = true" class="btn btn-primary pull-right mr-1"
                                    type="button">
                                   <fa-icon [icon]="faPaperclip" ></fa-icon>&nbsp; {{'master.Employee.label.attach' | translate}}
                                    <sup>
                                        <span *ngIf="FileList.length>0" class="badge badge-pill badge-info " style=" right: -16px;
                                            top: -13px;
                                            background: white;
                                            color: rebeccapurple;
                                            padding: 4px 6px;">{{FileList.length}}</span>

                                    </sup>

                                </button> -->
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                          
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <!-- Name -->
                                         <div class="col-md-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Registeron' | translate}}</label>
                                        <input type="text" name="Registeron" [(ngModel)]="model.ContactRegON"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required
                                            
                                            #Registeron="ngModel"
                                            #dp="bsDatepicker" class="form-control requiredIn" bsDatepicker>
                                        <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                            [attr.aria-expanded]="dp.isOpen"></i>

                                        <span *ngIf="formPage.submitted && !Registeron.valid" class="invalid-tooltip">
                                           Please Enter the Register On
                                        </span>
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Salution' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                           maxlength="75"
                                           
                                            name="Salution" #Salution="ngModel" [(ngModel)]="model.ContactSalutation"
                                            (keypress)="authService._restrictspecialchar($event)"  >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                            
                                       
                                        </span> -->
                                       
                                    </div>
                                    <!-- Code -->
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Firstname' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                            maxlength="75"
                                           
                                            name="Firstname" #Firstname="ngModel"
                                            [(ngModel)]="model.ContactFname"
                                            (keypress)="authService._restrictspecialchar($event)" required>
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !Firstname.valid" class="invalid-tooltip">
                                            Please Enter the First Name
                                         </span>

                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Middlename' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                            maxlength="75"
                                          
                                            name="Middlename" #Middlename="ngModel" [(ngModel)]="model.ContactMname"
                                            (keypress)="authService._restrictspecialchar($event)"  >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Lastname' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text" maxlength="75"
                                           
                                            name="Lastname" #Lastname="ngModel" [(ngModel)]="model.ContactLname"
                                            (keypress)="authService._restrictspecialchar($event)"
                                           required   >
                                        <!-- <span *ngIf="formPage.submitted && !firstName.valid && !model.firstName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg002' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !Lastname.valid" class="invalid-tooltip">
                                            Please Enter the Last Name
                                         </span>

                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.FullName' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                            maxlength="75"
                                          
                                            name="FullName" #FullName="ngModel" [(ngModel)]="model.ContactName"
                                            (keypress)="authService._restrictspecialchar($event)" required >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !FullName.valid" class="invalid-tooltip">
                                            Please Enter the Full Name
                                         </span>
                                    </div>


                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.ContactId' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text" maxlength="75"
                                          
                                            name="ContactId" #ContactId="ngModel" [(ngModel)]="model.ContactContactCode"
                                            (keypress)="authService._restrictspecialchar($event)"
                                            (ngModelChange)="getFullName()" required>
                                        <!-- <span *ngIf="formPage.submitted && !middleName.valid && !model.mname"
                                            class="invalid-tooltip">
                                          
                                            {{ 'Please Enter the Middle Name' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !ContactId.valid" class="invalid-tooltip">
                                            Please Enter the Contact ID
                                         </span>
                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Mr.mrs' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                            maxlength="75"
                                            
                                            name="mrs" #mrs="ngModel" [(ngModel)]="model.ContactPrefix"
                                            (keypress)="authService._restrictspecialchar($event)" required >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !mrs.valid" class="invalid-tooltip">
                                            Please Enter the Mr./Mrs
                                         </span>
                                    </div>


                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Type' | translate}}</label>
                                        <ng-select [items]="type" bindLabel="Value" bindValue="ID" [(ngModel)]="model.ContactType"
                                        name="Types" #Types="ngModel">
                                    </ng-select>
                                        <!-- <span *ngIf="formPage.submitted && !lastName.valid && !model.LastName"
                                            class="invalid-tooltip">
                                          
                                            {{ 'Please Enter the Last Name' | translate }}
                                        </span> -->

                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Dob' | translate}}</label>
                                        <input type="text" name="doj" [(ngModel)]="model.DateOfBirth"
                                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY' }"  required
                                           placeholder="MM/DD/YYYY"
                                            #doj="ngModel"
                                            #dp="bsDatepicker" class="form-control requiredIn" bsDatepicker>
                                        <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                            [attr.aria-expanded]="dp.isOpen"></i>
                                            <span *ngIf="formPage.submitted && !doj.valid" class="invalid-tooltip">
                                                Please Enter the Date Of Birth
                                             </span>
                                        <!-- <span *ngIf="formPage.submitted && !DOJ.valid" class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg024' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.SSN' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                             maxlength="75"
                                           
                                            name="SSN" #SSN="ngModel" [(ngModel)]="model.ContactsSSN"
                                            (keypress)="authService._restrictspecialchar($event)" required >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !SSN.valid" class="invalid-tooltip">
                                            Please Enter the SSN
                                         </span>
                                    </div>
                                     
                                   
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label>{{'master.Contacts.label.Country' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                            maxlength="75"
                                           
                                            name="country" #country="ngModel" [(ngModel)]="model.County"
                                            (keypress)="authService._restrictspecialchar($event)" required >
                                        <!-- <span *ngIf="formPage.submitted && !EmployeeName.valid && !model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg004' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !EmployeeName.valid && model.empName"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !country.valid" class="invalid-tooltip">
                                            Please Enter the Country
                                         </span>
                                    </div>


                                </div>

                            </div>
                        </div>


                        <div class="col-md-12 mb-4">
                            <div class="card text-left">
                                <form-wizard>
                                    <wizard-step [title]="'Primary Address'">
                                        <div class="row col-lg-12 pt-3">
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline1' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                   maxlength="75"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="Addressline1" #Addressline1="ngModel"
                                                    [(ngModel)]="model.ContactHAdd1" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress1.valid"
                                                    class="invalid-tooltip">
                                               
                                                    {{ 'Please Enter the Door No' | translate }}
                                                </span> -->
                                            </div>

                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline2' | translate}}</label>
                                                <input class="form-control form-control-rounded" type="text"
                                                    maxlength="75"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="txtPresAddress2" #txtPresAddress2="ngModel"
                                                    [(ngModel)]="model.ContactHAdd2"  >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress2.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>

                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline3' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="txtPresAddress3" #txtPresAddress3="ngModel"
                                                    [(ngModel)]="model.ContactHAdd3" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.City' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="City" #City="ngModel"
                                                    [(ngModel)]="model.ContactHcity">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.state' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="state" #state="ngModel"
                                                    [(ngModel)]="model.ContactHstate" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Country' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="Country" #Country="ngModel"
                                                    [(ngModel)]="model.ContactHcnty" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.PostalCode' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="PostalCode" #PostalCode="ngModel"
                                                    [(ngModel)]="model.ContactHpostal">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Email' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="email" #email="ngModel"
                                                    [(ngModel)]="model.ContactHemail">
                                                   <!-- <div class="email" style="margin-top: -34px; margin-left: 91%;">

                                                    <span class="input-group-text1" style="line-height: 20px;"
                                                        id="basic-addon2">@</span>
                                                </div> -->
                                                <div class="icondiv">
                                                    <fa-icon [icon]="faat" class="icon"></fa-icon>
                                                </div>

                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Mobile' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                    
                                                    name="Mobile" #Mobile="ngModel"
                                                    [(ngModel)]="model.ContactHmobile">
                                                    <div class="icondiv">

                                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Fax' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="Fax" #Fax="ngModel"
                                                    [(ngModel)]="model.ContactHfax" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.HomePhone' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="HomePhone" #HomePhone="ngModel"
                                                    [(ngModel)]="model.ContactHPh">
                                                   <div class="icondiv">

                                                    <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                </div>
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.WorkPhone' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="WorkPhone" #WorkPhone="ngModel"
                                                    [(ngModel)]="model.ContactHmobile">
                                                    <div class="icondiv">

                                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>


                                        </div>
                                    </wizard-step>
                                    <wizard-step [title]="'Secondary Address'">
                                        <div class="row col-lg-12 pt-3">
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline1' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                
                                                    name="add1" #add1="ngModel"
                                                    [(ngModel)]="model.ContactBusAdd1">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress1.valid"
                                                    class="invalid-tooltip">
                                               
                                                    {{ 'Please Enter the Door No' | translate }}
                                                </span> -->
                                            </div>

                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline2' | translate}}</label>
                                                <input class="form-control form-control-rounded" type="text"
                                                     maxlength="75"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="add2" #add2="ngModel"
                                                    [(ngModel)]="model.ContactBusAdd2">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress2.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>

                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Addressline3' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="add3" #add3="ngModel"
                                                    [(ngModel)]="model.ContactBusAdd3">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                               
                                               <label>{{'master.Contacts.label.City' | translate}}</label> 
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="City1" #City1="ngModel"
                                                    [(ngModel)]="model.ContactBusCity">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.state1' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="state1" #state1="ngModel"
                                                    [(ngModel)]="model.ContactBusState" >
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Country' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                  
                                                    name="Country1" #Country1="ngModel"
                                                    [(ngModel)]="model.ContactBusCnty">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.PostalCode' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="PostalCode1" #PostalCode1="ngModel"
                                                    [(ngModel)]="model.ContactBusPostal">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Email' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                   
                                                    name="Email1" #Email1="ngModel"
                                                    [(ngModel)]="model.ContactBusEmail">
                                                    <!-- <div class="email" style="margin-top: -34px; margin-left: 91%;">

                                                        <span class="input-group-text1" style="line-height: 20px;"
                                                            id="basic-addon2">@</span>
                                                    </div> -->

                                                    <div class="icondiv">
                                                        <fa-icon [icon]="faat" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                
                                                <label>{{'master.Contacts.label.Mobile' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                    
                                                    name="Mobile1" #Mobile1="ngModel"
                                                    [(ngModel)]="model.ContactBusMobile">
                                                  <div class="icondiv">

                                                    <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                </div>
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-4 col-md-4 col-lg-4 form-group mb-3">
                                                <label>{{'master.Contacts.label.Fax' | translate}}</label>
                                                <input class="form-control form-control-rounded" 
                                                    maxlength="75" type="text"
                                                    (keypress)="authService._restrictspecialchar($event)"
                                                    
                                                    name="Fax1" #Fax1="ngModel"
                                                    [(ngModel)]="model.ContactBusFax">
                                                <!-- <span *ngIf="formPage.submitted && !txtPresAddress3.valid"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
                                            </div>
                                        

                                        </div>
                                    </wizard-step>

                                   
                                </form-wizard>
                            </div>
                        </div>


                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">

                                    <div class="col-lg-12" style="text-align: end;" *ngIf="xpertsPageMode !=4">
                                        <button class="btn btn-light pull-right mr-1" (click)="clear()" type="button">
                                            <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                            }}</button>&nbsp;
                                        <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                            class="btn btn-success pull-right mr-1" type="submit">
                                            <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <p >Branch: {{d1|ddIndexValue:llist}}</p> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>