import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule, Http } from '@angular/http';
import { AuthService } from './shared/services/auth.service';
// import { PeriodComponent } from './views/master/period/period.component';
// import { SubProductComponent } from './views/master/sub-product/sub-product.component';
// import { EmployeeComponent } from './views/master/employee/employee.component';
import { MasterModule} from './views/master/master.module'
import { FormsModule }    from '@angular/forms';
import { XpertsPageModule } from './xperts-page/xperts-page.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select'; 
// import { UiSwitchModule } from 'ngx-toggle-switch';
// import { CRMModule} from './views/crm/crm.module';
import { UiKitsModule}from './views/ui-kits/ui-kits.module';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { NAV_DROPDOWN_DIRECTIVES } from './shared/directives/nav-dropdown.directive';

// Tansalator
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxEditorModule } from 'ngx-editor';
import { MessagingService } from './shared/Messaging-Service';
 import {AngularFireMessagingModule  }  from '@angular/fire/compat/messaging';
 import { AngularFireModule } from '@angular/fire/compat';
 import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
 import { XpertsPageComponent } from './xperts-page/xperts-page.component';

 
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/",".json");
}

@NgModule({
  declarations: [
    AppComponent
     
  ],
  imports: [
    BrowserModule,
    SharedModule,
    MasterModule,
    SharedPipesModule,
    UiKitsModule,
    NgSelectModule,
    NgbModalModule,
    NgbModule,
    FontAwesomeModule,
    XpertsPageModule,
    FormsModule,
    
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    HttpModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
      ServiceWorkerModule.register('../ngsw-worker.js', {
         enabled: environment.production,
       registrationStrategy: 'registerWhenStable:30000'
     }),

    NgxEditorModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
   
  ],
  providers: [
    AuthService,MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
