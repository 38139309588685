import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentPopupComponent } from './attachment-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [AttachmentPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CollapseModule.forRoot(),
    TranslateModule
  ],
  exports: [
    AttachmentPopupComponent,
  ],
  providers: [
    
  ]
})
export class AttachmentPopupModule { }
