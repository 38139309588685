import { Injectable } from "@angular/core";
  import { AngularFireMessaging } from '@angular/fire/compat/Messaging';
  import { AuthService } from 'src/app/shared/services/auth.service';
  import { ToastrService } from 'ngx-toastr';
  import { IpServiceService } from '../ip-service.service';  

@Injectable()
 export class MessagingService{
  model: any = {};
    sumitChanges: boolean = false;
    commonSave: string = 'Common.save';
    icon: string = "fa fa-save";
    ipAddress:string="";  
  constructor (private angularFirebaseMssaging : AngularFireMessaging,private authService : AuthService, private toastr : ToastrService,private ip:IpServiceService){
       

 }
 requestPerm(userName :any){
 // this.getIP();  
   this.angularFirebaseMssaging.requestToken.subscribe((token) => {
   
    console.log(token);
    console.log(userName);
    this.model= {
    
      "IPAddress":  this.ipAddress,
      "ClientToken": token,
      "BrowserType": this.detectBrowserName(),
      "CreaatedBy": "",
      "IsActive": true,
     
    }; 
    this.authService.PostNotification(this.model).subscribe(result => {
      
        
      if (result !== null) {
        this.sumitChanges = false;
        this.commonSave = 'Common.save';
        this.icon = "fa fa-save";
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
           
         
          
        }
        else if (result.Response._code == "222"){
          this.toastr.warning(result.Response._Description,"Warning");
           
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
        
      }
    },(err)=>{
       
    });

  },
(err) =>{

  console.error("No permission" + err);

  });

 }
 getIP()  
 {  
   this.ip.getIPAddress().subscribe((res:any)=>{  
     this.ipAddress=res.ip;  
   });  
 }

 detectBrowserName() { 
  
  const agent = window.navigator.userAgent.toLowerCase()
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
}
 }
