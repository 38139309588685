<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '1px' }"></ngx-loading> -->


<div class="container-fluid animated fadeIn bg-white">
    <div class="row d-flex">
        <div class="col-md-12 mb-2">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <i class="i-Monitor-2"></i>
                    <span style="font-size: 20px" matBadge="4" matBadgeOverlap="false">&nbsp;&nbsp;{{page.PageName}}

                    </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <!--  *ngIf="loginDetails.isdev!='False'" -->
                    <!-- <span (click)="navigateToSetting()" style="cursor: pointer;">
                        <i class="fa fa-cog pull-right mt-2 mr-1 fa-lg"></i>
                    </span> -->
                </div>
            </div>
        </div>
    </div>
    <div class="row d-flex align-items-center">
        <div class="col-md-6 pr-0 crmsearchContent" #Advdropdown (clickOutside)="onClickedOutside($event)">
            <form name="formFilters" #formFilters="ngForm" (submit)="searchValid(formFilters)">
                <!-- name="formFilters" #formFilters="ngForm" (submit)="searchValid(formFilters)" -->
                <div class="input-group crmsearch-bar">
                    <ng-container *ngFor="let filter of pageFilters; let index = index">
                        <ng-container *ngIf="filter.ControlType == 9">
                            <div class="col-md-6 form-group mb-3">
                                <label for="firstName">{{filter.DefaultLabel}}</label>
                                <!-- <input type="text" class="form-control form-control-rounded" name="name" #name="ngModel" minlength="2" maxlength="100" [(ngModel)]="model.PeriodName"
                                    (keypress)="authService._alphaAndSpecialchar($event)" placeholder="{{ 'master.period.label.periodName' | translate }}"
                                    required> -->
                                <input id="{{filter.FilterName}}" name="{{filter.FilterName}}" autofocus placeholder="{{filter.DefaultLabel}}" [(ngModel)]="modelDataFilters[index]"
                                    type="text" class="form-control form-control-rounded">
                            </div>
                        </ng-container>
                    </ng-container>
                    <input *ngIf="!checkControl9" type="text" placeholder="Search keywords..." name="advKeys" [(ngModel)]="keywords" class="form-control inputVal">
                    <div class="input-group-btn schVal">
                        <button type="button" style="height: 33px;" class="btn dropdown-toggle butnToggle" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                            aria-controls="collapseBasic">
                            <span class="caret"></span>
                        </button>
                        <button type="submit" class="btn butnSearch" matTooltip="Search" style="position: relative;left: -0.3em;height: 33px;">
                            <i class="{{serchIcon}}"></i>
                        </button>
                    </div>
                </div>

                <div class="advance-search" [collapse]="!isCollapsed">
                    <!--  [collapse]="!isCollapsed" -->
                    <div class="row">
                        <div *ngFor="let filter of pageFilters; let index = index" class="advsearch-field" [class.col-sm-6]='pageFilters.length>2'
                            [class.col-sm-12]='pageFilters.length<=2'>


                            <div class=" form-group mb-3" *ngIf="filter.ControlType == 1">
                                <label for="{{filter.FilterName}}">{{filter.DefaultLabel}}</label>
                                <input [readonly]="filter.IsDisabled == 1 || (filter.IsDisabled == 2 && currentUser.isadmin !='True')" required="{{filter.IsMandatory=='True'}}"
                                    class="form-control form-control-rounded" id="{{filter.FilterName}}" name="{{filter.FilterName}}"
                                    type="text" placeholder="{{filter.DefaultLabel}}" [(ngModel)]="modelDataFilters[index]">
                                <span *ngIf="filter.IsMandatory=='True' && formFilters.submitted && formFilters.controls[filter.FilterName].status=='INVALID'"
                                    style="font-size: 90%;color: red;" class="mat-error">
                                    Please Select the {{ filter.FilterLabel }}</span>
                            </div>

                            <div class="cus-mat-form-field" *ngIf="filter.ControlType == 2">
                                <label for="{{filter.FilterName}}">{{filter.DefaultLabel}}</label>
                                <ng-select [disabled]="filter.IsDisabled == 1 || (filter.IsDisabled == 2 && currentUser.isadmin !='True')" id="{{filter.FilterName}}"
                                    name="{{filter.FilterName}}" placeholder="{{filter.DefaultLabel}}" required="{{filter.IsMandatory=='True'}}"
                                    [(ngModel)]="modelDataFilters[index]" [items]="filter.ReferenceData" bindLabel="Value" bindValue="ID"
                                    (ngModelChange)="selectChange(filter.FilterName, $event, index)">
                                </ng-select>

                                <span *ngIf="filter.IsMandatory=='True' && formFilters.submitted && formFilters.controls[filter.FilterName].status=='INVALID'"
                                    style="font-size: 90%;color: red;" class="mat-error">
                                    Please Select the {{ filter.FilterLabel }}</span>
                            </div>
                            <div *ngIf="filter.ControlType == 3">
                                <label>{{filter.DefaultLabel}}</label>


                                <input type="text" name="{{filter.FilterName}}" [(ngModel)]="modelDataFilters[index]" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"
                                    readonly placeholder="{{filter.DefaultLabel}}" required="{{filter.IsMandatory=='True'}}"
                                    style="cursor: pointer;border-radius: 20px;" id="{{filter.FilterName}}" #dp="bsDatepicker"
                                    class="form-control requiredIn" bsDatepicker>
                                <i class="i-Calendar calenderStyle" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen"></i>
                                <span *ngIf="filter.IsMandatory=='True' && formFilters.submitted && formFilters.controls[filter.FilterName].status=='INVALID'"
                                    style="font-size: 90%;color: red;" class="mat-error">
                                    Please Select the {{ filter.FilterLabel }}
                                </span>
                            </div>


                            <mat-form-field *ngIf="filter.ControlType == 5">
                                <input [readonly]="filter.IsDisabled == 1 || (filter.IsDisabled == 2 && currentUser.isadmin !='True')" type="text" placeholder="Pick one"
                                    aria-label="Number" matInput [formControl]="myControl" [matAutocomplete]="auto" required="{{filter.IsMandatory=='True'}}">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option [value]="0">Choose</mat-option>
                                    <mat-option *ngFor="let option of filter.ReferenceData" [value]="option.ID">
                                        {{option.Value}}
                                    </mat-option>
                                </mat-autocomplete>
                                <button type="button" mat-button matSuffix mat-icon-button>
                                    <mat-icon class="ico-2x" matSuffix svgIcon="keyboard_ico"></mat-icon>
                                </button>
                            </mat-form-field>

                            <!-- <mat-checkbox *ngIf="filter.ControlType == 6" id="{{filter.FilterName}}">
                                {{filter.DefaultLabel}}
                            </mat-checkbox> -->
                            <div class="form-group form-check" *ngIf="filter.ControlType == 6">
                                <input type="checkbox" class="form-check-input" name="{{filter.FilterName}}" [(ngModel)]="modelDataFilters[index]" id="{{filter.FilterName}}">
                                <label class="form-check-label" for="{{filter.FilterName}}">
                                    {{filter.DefaultLabel}}
                                </label>
                            </div>
                            <div class="cus-mat-form-field" *ngIf="filter.ControlType == 7">
                                <label for="{{filter.FilterName}}">{{filter.DefaultLabel}}</label>
                                <ng-select [disabled]="filter.IsDisabled == 1 || (filter.IsDisabled == 2 && currentUser.isadmin !='True')" id="{{filter.FilterName}}"
                                    name="{{filter.FilterName}}" [multiple]="true" (change)="multiChange(filter.FilterName, $event.value, index)"
                                    placeholder="{{filter.DefaultLabel}}" required="{{filter.IsMandatory=='True'}}" [(ngModel)]="filter.ReferenceSelectData"
                                    [items]="filter.ReferenceData" bindLabel="Value" bindValue="ID" (ngModelChange)="selectChange(filter.FilterName, $event, index)">
                                </ng-select>
                            </div>

                        </div>
                        <div class="col-sm-12 text-right mt-3">
                            <button type="button" (click)="clear()" class="btn btn-light" matTooltip="Clear">
                                <i class="i-Minimize-Window pointer fa-1x"></i>&nbsp; Clear</button>
                            <button type="submit" class="btn btn-info ml-3" matTooltip="Search">
                                <i class="i-Magnifi-Glass1 pointer fa-1x"></i>&nbsp; Search</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <div class="col-md-6 text-right">
            <button *ngIf="showCreateBtn&&AccessPermission.Insert == 'True'" (click)="add()" matTooltip="Create" type="button" class="btn btn-outline-primary pull-right">
                <i class="i-Add pointer fa-1x"></i>&nbsp; Create</button>
            <button *ngIf="AccessPermission.Upload=='True'" (click)="UploadDialog()" matTooltip="Upload" type="button" class="mr-2 btn btn-outline-success pull-right">
                <i class="i-Upload1 pointer fa-1x"></i>&nbsp; Upload</button>
        </div>
    </div>

    <div class="row">
        <div class="{{model.gridCol}}">
            <div class="row d-flex align-items-center">
                <div class="col-md-12">
                    <div class="table-responsive table table-hover table-striped">
                        <table id="ul-contact-list" class="display table " style="width:100%">
                            <thead class="bg-fireFooter">
                                <!-- class="bg-fireHeader text-white" -->
                                <tr>
                                    <th *ngIf="this.page.IsRowSelectRequired==1">
                                        <input name="selectAll" type="checkbox" (change)="selectAll($event)" />
                                    </th>

                                    <th *ngFor="let column of pageResult; let index = index" [hidden]="column.IsVisible=='False'" [hidden]=" column.IsNavigate=='True'">
                                        {{column.ResultName}}</th>
                                    <th style="text-align: center;">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of pageGridBindingData; let index = index">
                                    <td *ngIf="this.page.IsRowSelectRequired==1">
                                        <input [(ngModel)]="row.select" type="checkbox" (change)="select($event)" />
                                    </td>

                                    <td *ngFor="let cell of row.data; let idx = index" [hidden]="pageResult[idx].IsNavigate.toLowerCase() == 'true'" [style.text-align]="alignType(pageResult[idx].ResultType)"
                                        (click)="AccessPermission.Update == 'True' && edit(index)" id="{{idx}}" [ngStyle]="{'border-top':pageResult[idx].CellColor == '' ? '' : '4px solid #f2f2f2','border-left':pageResult[idx].CellColor == '' ? '' : '4px solid','border-left-color':pageResult[idx].CellColor == '' ? '' : pageColor[cell] | lowercase, 'color':pageResult[idx].CellColor == '' ? '' : pageColor[cell] | lowercase}">
                                        <label>{{cell}} </label>

                                    </td>

                                    <td class="text-center">

                                        <!-- <i *ngIf="AccessPermission.View == 'True'" class="i-Eye text-primary"
                                            matTooltip="View" (click)="view(index)"></i> -->
                                        <fa-icon [icon]="faEye" *ngIf="AccessPermission.View == 'True'" data-toggle="tooltip" title="View" style="color:Blue; cursor: pointer;"
                                            (click)="view(index)"></fa-icon>
                                        &nbsp;
                                        <!-- <i *ngIf="AccessPermission.Update == 'True'"
                                            class="i-Edit pointer zoom pr-1 pl-1 text-success" matTooltip="Edit"
                                            (click)="edit(index)"></i> -->
                                        <fa-icon [icon]="faEdit" *ngIf="AccessPermission.Update == 'True'" data-toggle="tooltip" title="Edit" style="color:green; cursor: pointer;"
                                            (click)="edit(index)"></fa-icon>
                                        &nbsp;
                                        <i *ngIf="AccessPermission.Copy == 'True'" class="i-File-Copy-2 pointer zoom pr-1 pl-1 text-success" matTooltip="Copy" (click)="copy(index)"></i>
                                        <!-- <i *ngIf="AccessPermission.Delete == 'True'"
                                            class="i-Remove pointer zoom pr-1 pl-1 text-danger" matTooltip="Delete"
                                            (click)="openDialog(index)"></i> -->
                                        <fa-icon [icon]="faTrashAlt" *ngIf="AccessPermission.Delete == 'True'" data-toggle="tooltip" title="Delete" style="color:red; cursor: pointer;"
                                            (click)="openDialog(index)"></fa-icon>
                                        <i *ngIf="page.ExtraOptions==1" class="i-Align-Justify-All pointer zoom pr-1 pl-1 text-success" matTooltip="More" (click)="more(index)"></i>


                                    </td>
                                </tr>
                                <tr *ngIf="errorMsg!=''">
                                    <td colspan="50" align="center">{{errorMsg}}</td>
                                </tr>
                                <tr *ngIf="pageGridBindingData.length==0 && errorMsg==''">
                                    <td colspan="50" align="center">No Data Found..!</td>
                                </tr>


                            </tbody>
                            <tfoot class="bg-fireFooter">
                                <tr>
                                    <th *ngIf="this.page.IsRowSelectRequired==1">&nbsp;&nbsp;</th>
                                    <th [hidden]="column.IsNavigate == 'True'" *ngFor="let column of pageResult; let index = index">
                                        &nbsp;&nbsp;</th>
                                    <th>&nbsp;&nbsp;</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>


            <div class="col-lg-12" *ngIf="pageGridBindingData.length">
                <div class="row d-flex align-items-center" style="float: right;">
                    <label class="botLable">Item Per Page</label>
                    <ng-select [items]="pageSizeOptions" [clearable]="false" ngDefaultControl bindLabel="Value" bindValue="ID" (ngModelChange)="pageSizeChange($event)"
                        #ddltaxMultiTax="ngModel" name="ddltaxMultiTax" [(ngModel)]="pageOption">
                    </ng-select>


                    <ul class="pagination pagination">
                        <li (click)="first()">
                            <a>
                                <i class="icn i-Arrow-Left-2"></i>
                            </a>
                        </li>
                        <li (click)="left()">
                            <a>
                                <i class="icn i-Arrow-Left "></i>
                            </a>
                        </li>
                        <!-- <li class="page_num px-3"></li> -->
                        <span>{{authenticationService.currentIndexFrom}}-{{authenticationService.currentIndexTo}} of {{totalPageCount}}
                        </span>
                        <li (click)="right(1)">
                            <a>
                                <i class="icn i-Arrow-Right"></i>
                            </a>
                        </li>
                        <li (click)="right(2)">
                            <a>
                                <i class="icn i-Arrow-Right-2"></i>
                            </a>
                        </li>
                        <!-- <fa-icon [icon]="faArrowLeft" title="Edit"></fa-icon> -->

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>