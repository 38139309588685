<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>


<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode=0"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{'master.casetype.heading' | translate}}
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;">
                                <button class="btn btn-raised btn-raised-secondary pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}

                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <div class="col-lg-6 mb-3">
                                <label for="CategoryGUID">{{'master.casetype.label.casetype' | translate}}</label>
                                <input class="form-control form-control-rounded requiredIn " minlength="2" maxlength="50"
                                    name="casetype" #casetype="ngModel" [(ngModel)]="model.CaseTypeName" required>
                                <span *ngIf="formPage.submitted && !casetype.valid"  class="invalid-tooltip">
                                   Please Select a Case Type
                                </span>
                            </div>


                            <div class="col-lg-6 mb-3">
                                <label for="name">{{'master.casetype.label.Desc' | translate}}</label>
                                <input class="form-control form-control-rounded " minlength="2" maxlength="50"
                                    name="txtStateName" #txtStateName="ngModel" [(ngModel)]="model.CaseTypeDesc" >
                                <!-- <span *ngIf="formPage.submitted && !txtStateName.valid && !model.Name"  class="invalid-tooltip">
                                        {{ 'master.State.message.msg001' | translate }}
                                    </span>
                                    <span *ngIf="formPage.submitted && !txtStateName.valid && model.Name"  class="invalid-tooltip">
                                        {{ 'master.State.message.msg003' | translate }}
                                    </span> -->
                            </div>



                        </div>
                    </div>
                    <div class="card-footer" style="padding:10px 20px 10px 15px;">
                        <div class="row">
                            <div class="col-md-6 pl-4 mt-2">
                                <div class="row">

                                    <label class="switch">
                                        <input name="ch" type="checkbox" [(ngModel)]="model.Status" #ch="ngModel">
                                        <span class="slider round"></span>
                                    </label>
                                    <span *ngIf="model.Status=='Active'">{{'Common.active' | translate }}</span>
                                    <span *ngIf="model.Status=='InActive'">{{'Common.inActive' | translate }}</span>
                                </div>
                            </div>
                            <!-- <div class="col-md-6" style="padding: 0px 10px 0px 0px; text-align: right;">
                                <button class="btn btn-raised btn-raised-secondary pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}
                                </button>

                            </div> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> -->
</div>