<div class="breadcrumb">
  <h1>Widgets</h1>
  <ul>
    <li><a href="">UI Kits</a></li>
    <li>Cards Widgets</li>
  </ul>
</div>

<div class="separator-breadcrumb border-top"></div>

<div class="row">
  <div class="col-md-6">
    <div class="card mb-4">
      <div class="card-body">
        <div class="card-title m-0">Network Stats</div>
        <p class="text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="p-4 rounded d-flex align-items-center bg-primary text-white">
              <i class="i-Data-Backup text-32 mr-3"></i>
              <div>
                <h4 class="text-18 mb-1 text-white">Backups</h4>
                <span>Total: 32</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="p-4 rounded d-flex align-items-center bg-primary text-white">
              <i class="i-Big-Data text-32 mr-3"></i>
              <div>
                <h4 class="text-18 mb-1 text-white">Databases</h4>
                <span>Total: 302</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="p-4 border border-light rounded d-flex align-items-center">
              <i class="i-Data-Cloud text-32 mr-3"></i>
              <div>
                <h4 class="text-18 mb-1">Space used</h4>
                <span>Total: 160GB</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="p-4 border border-light rounded d-flex align-items-center">
              <i class="i-Data-Download text-32 mr-3"></i>
              <div>
                <h4 class="text-18 mb-1">Downloaded</h4>
                <span>Total: 30GB</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card mb-4">
      <div class="card-body">
        <div class="card-title">Top Selling Products</div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/products/headphone-2.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">Wireless Headphone</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <span class="font-weight-bold text-primary text-16">$450</span>
          </div>
        </div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/products/headphone-3.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">Headphone Black</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <span class="font-weight-bold text-warning text-16">$350</span>
          </div>
        </div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/products/iphone-2.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">iPhone 6</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <span class="font-weight-bold text-danger text-16">$649</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <img class="avatar-md rounded mr-3" src="./assets/images/products/headphone-4.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">Headphone Red</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <span class="font-weight-bold text-secondary text-16">$150</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card mb-4">
      <div class="card-body">
        <div class="card-title">Top Users</div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/faces/2.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">David Hopkins</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <button class="btn btn-outline-primary btn-rounded btn-sm">Follow</button>
          </div>
        </div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/faces/3.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">James Mitchell</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
              <button class="btn btn-outline-primary btn-rounded btn-sm">Follow</button>
          </div>
        </div>
        <div class="d-flex align-items-center border-bottom-dotted-dim pb-3 mb-3">
          <img class="avatar-md rounded mr-3" src="./assets/images/faces/4.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">John Doe</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
              <button class="btn btn-outline-primary btn-rounded btn-sm">Follow</button>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <img class="avatar-md rounded mr-3" src="./assets/images/faces/5.jpg" alt="">
          <div class="flex-grow-1">
            <h6 class="m-0">Dan Hill</h6>
            <p class="m-0 text-small text-muted">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
          <div>
            <button class="btn btn-outline-primary btn-rounded btn-sm">Follow</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card card-chart-bottom o-hidden mb-4">
      <div class="card-body">
        <div class="text-muted">Last Week Sales</div>
        <p class="mb-4 text-primary text-24">$1250</p>
      </div>
      <div echarts [options]="chartOption1" [autoResize]="true" style="height: 260px;"></div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card card-chart-bottom o-hidden mb-4">
      <div class="card-body">
        <div class="text-muted">Last Month Sales</div>
        <p class="mb-4 text-warning text-24">$40250</p>
      </div>
      <div echarts [options]="chartOption2" [autoResize]="true" style="height: 260px;"></div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card">
      <div class="card-body">
        <div class="card-title">Last Month Summary</div>
        <div class="row">
          <div class="col-md-6">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Item</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Portable Speaker</td>
                  <td class="font-weight-bold text-success">+ $1200</td>
                </tr>
                <tr>
                  <td>Portable Headphone</td>
                  <td class="font-weight-bold">In Stock</td>
                </tr>
                <tr>
                  <td>Speaker</td>
                  <td class="text-danger font-weight-bold">Out of stock</td>
                </tr>
                <tr>
                  <td>Watch</td>
                  <td class="text-warning font-weight-bold">Low stock</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
              <div echarts [options]="chartPie1" [autoResize]="true" style="height: 200px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>