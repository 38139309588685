import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import {  NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid'
import { faSave,faEraser, faTurkishLiraSign } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {

  model: any = {};
  screenTypeList: any = [];
  ddlCurrency:any=[];
  ddlCountryZone:any=[];
  xpertsPageMode: number;
  sumitChanges: boolean = false;
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faSave=faSave;
  faEraser=faEraser;
  badgeStatus:string;
  FileUploadData: any=[];
  showAttachement: boolean = false;


  FileList: any = [];

  
  ProgressSpinner: boolean;

  constructor(private authService: AuthService, private toastr: ToastrService) {
   
    }
  //  Fileuploaded(e) {
  //   this.showAttachement = e.show;
  //   this.FileList = e.data;
  // }
   newForm(){
    this.model= {
    
      "CountryCode": "",
      "CountryOUCode": "",
      "CountryName": " ",
      "ISDCode": "",
      "CountryDesc": "",
      "Status": 'active',
    }; 
    this.badgeStatus='New';   
  }
  ngOnInit() {
    this.newForm();
  }

  // checkUncheckactiveStatus(val) {

  //   console.log("val",val);

  //   if (confirm(this.pageData.addEmployee.validation.InactiveConfrim)) {
  //     console.log("this.pageData.addEmployee.validation.InactiveConfrim", 
  //     this.pageData.addEmployee.validation.InactiveConfrim);
  //     this.model.isActive != this.model.isActive;      
      
  //   }
  // }

  pageMode(mode){
    this.xpertsPageMode = mode;
    this.newForm();
  }

 
  back(){
    this.newForm();
  }
  clear(){
    this.newForm();
  }
  
  postForm(form: NgForm){    

   
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    
    this.ProgressSpinner = true; 
    if(this.model.Status == true){
      this.model.Status = 'active'
    }
    else if(this.model.Status == false){
      this.model.Status = 'inactive'
    }

    this.authService.PostCountry(this.model).subscribe(result => {
      
    this.ProgressSpinner = false;
      if (result !== null) {
        this.sumitChanges = false;
        this.commonSave = 'Common.save';
        this.icon = "fa fa-save";
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
          this.xpertsPageMode = 0;
          
        }
        else if (result.Response._code == "222"){
          this.toastr.warning(result.Response._Description,"Warning");
          this.xpertsPageMode=1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
        
      }
    },(err)=>{
      this.ProgressSpinner=false;
    });
  }

  pageEditData(pageData){
    let req ={'CountryCode':pageData[0]};
    this.ProgressSpinner = true;
    this.authService.GetCountry(req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) {
        if (result.Response._code == "111") {
         
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.Country[0]);

           if(this.model.Status.toLowerCase() == 'active'){
            this.model.Status = true
          }
          else if(this.model.Status.toLowerCase() == 'inactive'){
            this.model.Status =  false
          }

          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.Country[0]);

          }else if(this.xpertsPageMode == 3){
            this.badgeStatus='Copy';
            this.model = Object.assign({},result.Country[0]);
           // this.model.CountryGUID = UUID.UUID();
          }
        }else{          
            this.toastr.error(result.Response._Description, 'Error');
        }        
      }
    }); 
  }
}
