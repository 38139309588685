import { Component, OnInit } from '@angular/core';
import { faEraser, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-county',
  templateUrl: './county.component.html',
  styleUrls: ['./county.component.scss']
})
export class CountyComponent implements OnInit {
  model:any={};
  screenTypeList: any = [];
  ddlCurrency:any=[];
  ddlCountryZone:any=[];
  xpertsPageMode: number;
 
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faSave=faSave;
  faEraser=faEraser;
  badgeStatus:string;
  constructor() { }
  newForm(){
  this.model={
    "county":"",
    "state":"",
    "desc":"",
    "Status":""

  }
}
pageMode(mode){
  this.xpertsPageMode = mode;
  this.newForm();
}
back(){
  this.newForm();
}
clear(){
  this.newForm();
}
  ngOnInit(): void {
  }

}
