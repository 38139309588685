import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterRoutingModule } from './master-routing.module';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { ContactsComponent } from './contacts/contacts.component';
import { CountryComponent } from './country/country.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormWizardModule } from 'src/app/shared/components/form-wizard/form-wizard.module';
import { StateComponent } from './state/state.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CityComponent } from './city/city.component';
import { CaseTypeComponent } from './case-type/case-type.component';
import { EmployeeComponent } from './employee/employee.component';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientComponent } from './client/client.component';
import { CallLogComponent } from './call-log/call-log.component';
import { EventTypeComponent } from './event-type/event-type.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SourceComponent } from './source/source.component';
import { RefrsComponent } from './refrs/refrs.component';
import { ActivityComponent } from './activity/activity.component';
import { BillingCatagoryComponent } from './billing-catagory/billing-catagory.component';
import { BillingRateComponent } from './billing-rate/billing-rate.component';
import { CalenderComponent } from './calender/calender.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { XpertsPageModule } from '../../xperts-page/xperts-page.module';
import { AttachmentPopupModule } from '../attachment-popup/attachment-popup.module';
import { CountyComponent } from './county/county.component';




@NgModule({
  declarations: [
    DeletePopupComponent,
    ContactsComponent,
    CountryComponent,
    StateComponent,
    CityComponent,
    CaseTypeComponent,
    EmployeeComponent,
    ClientComponent,
    CallLogComponent,
    EventTypeComponent,
    SourceComponent,
    RefrsComponent,
    ActivityComponent,
    BillingCatagoryComponent,
    BillingRateComponent,
    CalenderComponent,
    CountyComponent
  ],
  imports: [
    XpertsPageModule,
    AttachmentPopupModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
    MasterRoutingModule,
    NgbDatepickerModule,
    FormWizardModule,
    BsDatepickerModule.forRoot(),
    MatDialogModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaterialTimepickerModule


  ], exports: [BsDatepickerModule]
})
export class MasterModule { }
