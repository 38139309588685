import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { XpertsPageModule } from '../../../xperts-page/xperts-page.module';
import { faSave, faEraser, faPaperclip, faPhone, faAt, faClock, faUsd } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  model: any = {};
  faPhone = faPhone;
  currDate: Date = new Date()
  showAttachement: boolean = false;
  xpertsPageMode: number;
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faSave = faSave;
  faEraser = faEraser;
  faPaperclip = faPaperclip;
  badgeStatus: string;
  ProgressSpinner: boolean = false;
  faclocko = faClock;
  fausd = faUsd;
  faat = faAt;
  type: any = [];
  constructor(private TranslateService: TranslateService,
    private authService: AuthService,
    private toastr: ToastrService) {

  }

  newForm() {
    this.model = {

      "ContactCode": "",
      "ContactFname": "",  //
      "ContactLname": "", //
      "ContactName": "",//
      "ContactContactCode": "",
      "ContactType": "",  //
      "ContactPrefix": "",//
      "ContactSalutation": "",//
      "ContactHAdd1": "",  //
      "ContactHAdd2": "",  //
      "ContactHAdd3": "",//
      "ContactHcity": "",//
      "ContactHstate": "", // 
      "ContactHcnty": "", //  
      "ContactHpostal": "",//
      "ContactHemail": "",//
      "ContactHmobile": "", // 
      "ContactBusAdd1": "",
      "ContactBusAdd2": "",
      "ContactBusAdd3": "",
      "ContactBusCity": "",
      "ContactBusState": "",
      "ContactBusCnty": "",
      "ContactBusPostal": "",
      "ContactBusEmail": "",
      "ContactBusMobile": "",
      "ContactMname": "",//
      "ContactHlacode": "",
      "ContactHPh": "", // 
      "ContactBuslacode": "",
      "ContactBusPh": "",
      "ContactBusFax": "",
      "ContactHfax": "",
      "ContactRegON": "", // 
      "ContactMatterId": "",
      "ContactSUFFIX": "",
      "ContactINITIALS": "",
      "ContactHPH1": "",
      "ContactHPH2": "",
      "ContactHPH3": "",
      "ContactHPH4": "",
      "ContactBPH1": "",
      "ContactBPH2": "",
      "ContactBPH3": "",
      "ContactBusWeb": "",
      "ContactOtherEmail": "",
      "ContactHomeWeb": "",
      "ContactBUSPH2": "",
      "IsAccountPosted": "",
      "DateOfBirth": "",
      "ContactsSSN": "",
      "County": "",
      "Status": "Active"

    },

      this.badgeStatus = 'New';
  }
  ngOnInit() {
    this.loadDropDown();
  }
  pageMode(mode) {
    this.xpertsPageMode = mode;
    this.newForm();
  }


  loadDropDown() {
    this.authService.GetDropDownList('ContactType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.type = result.Dropdownlist
        }
      }
    })
  }
  postForm(form: NgForm) {

    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
    this.authService.CrmPostJsonService(this.authService.masterApi, 'PostContact', this.model).subscribe(result => {
      this.ProgressSpinner = false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      console.log("this.model", this.model);
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
          this.xpertsPageMode = 1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }

  pageEditData(pageData) {//getting response from api in edit mode
    let req = {
      "ContactCode": pageData[0]
    };
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetContact", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) {
        if (result.Response._code == "111") {
          this.model = Object.assign({}, result.Contact);
          if (this.xpertsPageMode == 2) {
            this.badgeStatus = 'Edit';
            this.model = Object.assign({}, result.Contact[0]);
          } else if (this.xpertsPageMode == 4) {
            this.badgeStatus = 'View';
            this.model = Object.assign({}, result.Contact[0]);
          } else if (this.xpertsPageMode == 3) {
            //  this.badgeStatus='Copy';
            this.model = Object.assign({}, result.Contact[0]);

          }
        }
      }
    });

  }
  clear() {
    this.newForm();
  }



}
