import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XpertsPageRoutingModule } from './xperts-page-routing.module';
import { XpertsPageComponent } from './xperts-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from "ngx-bootstrap/modal";
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [XpertsPageComponent],
  imports: [
    BsDatepickerModule.forRoot(),
    CommonModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    XpertsPageRoutingModule,
    MatDialogModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
  ],
  exports: [
    BsDatepickerModule,
    XpertsPageComponent, 
  ],
})
export class XpertsPageModule { }
