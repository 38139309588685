<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>

<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode=0"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{'master.calllog.heading' | translate}}
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                           
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 form-group mb-3">
                                <label>Date</label>
                                <input type="text" name="doj" [(ngModel)]="model.CallLogDate"
                                    [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }"
                                    #DOJ="ngModel" #dp="bsDatepicker"
                                    class="form-control " bsDatepicker>
                                <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                    [attr.aria-expanded]="dp.isOpen"></i>

                              
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label>{{'master.calllog.label.logby' | translate}}</label>
                                <ng-select [items]="calllog" bindLabel="Value" bindValue="ID" [(ngModel)]="model.CallLogBy" name="CountryGUID"
                                    #ddlCountryGUID="ngModel">
                                </ng-select>
                               
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Salution</label>
                                <input class="form-control form-control-rounded "
                                  maxlength="50"
                                 
                                    name="salution" #salution="ngModel" [(ngModel)]="model.Salutation"  > 
                                   
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">First Name</label>
                                <input class="form-control form-control-rounded "
                                maxlength="50"
                                 
                                    name="firstname" #firstname="ngModel"[(ngModel)]="model.FirstName"> 
                                  
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Middle Name</label>
                                <input class="form-control form-control-rounded "
                                 maxlength="50"
                                 
                                    name="middlename" #middlename="ngModel" [(ngModel)]="model.MiddleName"  > 
                                   
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Last Name</label>
                                <input class="form-control form-control-rounded "
                                 maxlength="50"
                                 
                                    name="lastname" #lastname="ngModel" [(ngModel)]="model.LastName"   > 
                                   
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Full Name</label>
                                <input class="form-control form-control-rounded "
                                 maxlength="50"
                                 
                                    name="fullname" #fullname="ngModel" [(ngModel)]="model.FullName"  > 
                                   
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Contact Number</label>
                                <input class="form-control form-control-rounded "
                                 maxlength="50"
                                 
                                    name="contact" #contact="ngModel" [(ngModel)]="model.ContactNumber"  > 
                                    <div class="icondiv">
                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                    </div>
                                  
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label for="name">Email</label>
                                <input class="form-control form-control-rounded "
                                  maxlength="50"
                                 
                                    name="email" #email="ngModel" [(ngModel)]="model.Gmail"  > 
                                    <div class="icondiv">
                                        <fa-icon [icon]="faat" class="icon"></fa-icon>
                                    </div>
                                  
                            </div>
                            <div class="col-md-3 form-group mb-3">
                                <label>Call Type</label>
                                <ng-select  [items]="Calltype" bindLabel="Value" bindValue="ID" [(ngModel)]="model.CallType" name="calltype"
                                    #calltype="ngModel">
                                </ng-select>
                               
                            </div>

                        </div>
                    </div>
                    <div class="card-footer" style="padding:10px 20px 10px 15px;">
                        <div class="row">
                            <div class="col-md-6 pl-4 mt-2">
                                <div class="row">

                                    <label class="switch">
                                        <input name="ch" type="checkbox" [(ngModel)]="model.Status" (click)="toggle()"  #ch="ngModel">
                                        <span class="slider round"></span>
                                        <h1>{{model.Status}}</h1>
                                    </label>
                                    <span *ngIf="model.Status=='Active'">{{'Common.active' | translate }}</span>
                                    <span *ngIf="model.Status=='InActive'">{{'Common.inActive' | translate }}</span>
                                    
                                </div>
                            </div>
                            <div class="col-md-6" style="padding: 0px 10px 0px 0px; text-align: right;">
                                <button class="btn btn-raised btn-raised-secondary pull-right mr-1" (click)="clear()" type="button">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> -->
</div>