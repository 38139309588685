<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>
<!-- <app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup> -->
<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode = 0;back()" type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp;{{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{'master.country.heading' | translate}}
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;">
                               
                              
                                <button class="btn btn-light" (click)="clear()"
                                  type="button">
                                  <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                }}</button>&nbsp;
                                <button
                                    class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="txtCountryName">{{'master.country.label.CountryName' | translate}}</label>
                                <input class="form-control form-control-rounded requiredIn" type="text"
                                    placeholder="{{ 'master.Country.label.CountryName' | translate }}"
                                     maxlength="25"
                                    name="txtCountryName" #txtCountryName="ngModel" [(ngModel)]="model.CountryName"
                                     required>
                                <!-- <span *ngIf="formPage.submitted && !txtCountryName.valid && !model.CountryName"
                                     class="invalid-tooltip">
                                    {{ 'master.Country.message.msg001' | translate }}
                                </span>
                                <span *ngIf="formPage.submitted && !txtCountryName.valid && model.CountryName"
                                    class="invalid-tooltip">
                                    {{ 'master.Country.message.msg002' | translate }}
                                </span> -->

                            </div>
  
                            <div class="col-lg-4">
                                <label for="countryRemarks">{{'master.country.label.Remark' | translate}}</label>
                                <input class="form-control form-control-rounded" type="text" maxlength="150"
                                    placeholder="{{ 'master.Country.label.Remark' | translate }}" name="countryRemarks"
                                    #countryRemarks="ngModel" [(ngModel)]="model.CountryDesc"
                                    (keypress)="authService._restrictspecialchar($event)">


                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-6 pl-4 pt-2">
                                <div class="row">

                                    <label class="switch">
                                        <input name="ch"  type="checkbox" [(ngModel)]="model.Status" >
                                        <span class="slider round"></span>
                                       
                                    </label>
                                     
                                    <span *ngIf="model.Status == true">Active</span>
                                    <span *ngIf="model.Status == false">InActive</span>

                                    <!-- <span >{model.Status ? 'Active': 'Inactive'}</span>    -->
                                   
                                </div>

                                <!-- <div class="col-md-6">
                                    <label *ngIf="model.Status == 0" class="toggle-button" style="margin-right: 10px;"> 
                                       In Active</label>
                                    <label *ngIf="model.Status == 1" class="toggle-button"
                                        style="margin-right: 10px;"> Active</label>
                                    <label class="switch switch-primary mr-3">
                                        <input type="checkbox" name="statusActive" [(ngModel)]="model.Status"
                                            [checked]="model.Status">
                                        <span class="slider" ></span>
                                    </label>

                                    <p>{{model.Status}}</p>
                                </div> -->

                            </div>
                            <!-- <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">
                              
                                <button class="btn btn-light" (click)="clear()"
                                  *ngIf="xpertsPageMode==1"  type="submit">
                                  <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                }}</button>&nbsp;
                                <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                    class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}
                                </button>
                            </div> -->


                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
</div>