import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { XpertsPageModule } from '../../../xperts-page/xperts-page.module';
import { faSave,faEraser,faPaperclip, faPhone, faAt, faUsd, faClock } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {

  model: any = {};
  xpertsPageMode: number;
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faclocko = faClock;
  fausd = faUsd;
  faat = faAt;
  faSave = faSave;
  faPhone=faPhone;
  faEraser = faEraser;
  faPaperclip=faPaperclip;
  
  isShift: boolean = false;
  badgeStatus: string;
  ProgressSpinner: boolean = false;
  agent:any=[];
  clientid:any=[];
  matterid:any=[];
  eventtype:any=[];
  eventcatagory:any=[];

  constructor(public authService: AuthService, private toastr: ToastrService){

    }


  newForm() {
    this.model={
  "EventCalCode":"",
  "EventCalEvtDt": "",
  "EventCalAgentID": "",
  "EventCalClientID": "",
  "EventCalMatterID": "",
  "EventCalEvtTyp": "",
  "EventCalEvtTitle": "",
  "EventCalSchDt": "",
  "EventCalSchtim": "",
  "EventCalSchDur": "",
  "EventCalCate": "",
  "EventCalLoc": "",
  "EventCalNote": "",
  "Status":"Active"
    }
      this.badgeStatus = 'New';
  
  }

  ngOnInit() {
   this.loadDropDown();
  //  this.getDropdownMatterID(event);
  
  }


  getDropdownMatterID(event) {
    
    console.log("getName", event)
    var str = event.term;
    if (str.length >= 3) {
     // let req = { FilterRef: 'MatterID', DepedentFieldValue: str }
      this.authService.GetDropDownList('MatterID',str).subscribe(result => {
        if (result !== null) {
          if (result.Response._code == "111") {
            this.matterid = result.Dropdownlist;
            console.log("matterid",this.matterid);
           
          } else {
            this.matterid = []
            // this.toastr.error(result.response._Description, 'Error');
          }
        }
      })
    }
    else {
      this.matterid = [];
    }
  }
  getDropdownClientID(event) {
    
    console.log("getName", event)
    var str = event.term;
    if (str.length >= 3) {
     // let req = { FilterRef: 'MatterID', DepedentFieldValue: str }
      this.authService.GetDropDownList('Client',str).subscribe(result => {
        if (result !== null) {
          if (result.Response._code == "111") {
            this.clientid = result.Dropdownlist;
            console.log("matterid",this.matterid);
           
          } else {
            this.clientid = []
            // this.toastr.error(result.response._Description, 'Error');
          }
        }
      })
    }
    else {
      this.clientid = [];
    }
  }

  loadDropDown() {

    this.authService.GetDropDownList('Agent').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.agent = result.Dropdownlist
        }
      }
    })


    // this.authService.GetDropDownList('Client').subscribe(result => {
    //   if (result !== null) {
    //     if (result.Response._code == "111") {
    //       this.clientid = result.Dropdownlist
    //     }
    //   }
    // })

    
    // this.authService.GetDropDownList('MatterID').subscribe(result => {
    //   if (result !== null) {
    //     if (result.Response._code == "111") {
    //       this.matterid = result.Dropdownlist
    //     }
    //   }
    // })

    this.authService.GetDropDownList('EventType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.eventtype = result.Dropdownlist
        }
      }
    })

    this.authService.GetDropDownList('EventCategory').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.eventcatagory = result.Dropdownlist
        }
      }
    })
  }
 pageMode(mode) {
  this.xpertsPageMode = mode;
  this.newForm();
}

postForm(form: NgForm) {
  this.commonSave = 'Common.process';
  this.icon = "fa fa-spinner fa-spin";
 
  
  this.ProgressSpinner = true;
  this.authService.CrmPostJsonService(this.authService.CaseApi, 'PostEventCal', this.model).subscribe(result => {
    this.ProgressSpinner = false;
    
    this.commonSave = 'Common.save';
    this.icon = "fa fa-save";
    if (result !== null) {
      if (result.Response._code == "111") {
        this.toastr.success(result.Response._status, "Success");
      
        form.resetForm();
        // this.pageEditData();
         this.xpertsPageMode = 0;
      }
      else if (result.Response._code == "222") {
        this.toastr.warning(result.Response._Description, "Warning");
         this.xpertsPageMode = 1;
      }        
    }else {
      this.toastr.error(result.Response._Description, 'Error');
    }
  },(err)=>{
    this.ProgressSpinner=false;
  });
}

pageEditData(pageData) {//getting response from api in edit mode
  let req = { 
    "EventCalCode":pageData[0]
  };
 
  this.ProgressSpinner = true;
  this.authService.CrmGetJsonService(this.authService.CaseApi, "GetEventCal", req).subscribe(result => {
    this.ProgressSpinner = false;
    if (result !== null) { 
      if (result.Response._code == "111") {
        this.model=Object.assign({},result.EventCal);
        if(this.xpertsPageMode == 2){
          this.badgeStatus='Edit';
         this.model = Object.assign({},result.EventCal[0]);
        }else if(this.xpertsPageMode == 4){
          this.badgeStatus='View';
         this.model = Object.assign({},result.EventCal[0]);
        }else if(this.xpertsPageMode == 3){
        //  this.badgeStatus='Copy';
          this.model = Object.assign({},result.EventCal[0]);
         
        }
  }
  
}
  });
}

}
