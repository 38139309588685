import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  @ViewChild('content')
  private modalRef: TemplateRef<any>;
  @Output() status = new EventEmitter<any>();
  guid: any;
  type: any;
  faCheck = faCheck;

  constructor(private modalService: NgbModal,public dialogRef: MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { console.log(data, 'data');}

  ngOnInit(): void {
  }
  onClose(){
    this.modalService.dismissAll();
   this.dialogRef.close('No');
 }
 confirmORCancel(e){
  if(e=='2'){
    let obj ={
      e:e,
      type:this.type,
      guid:this.guid
    }
   this.status.emit(obj);
  }

 this.modalService.dismissAll();
 this.dialogRef.close('No');
}
 
confirmDialog(){
  // this.modalService.open(this.modalRef, { size: 'md', backdrop: 'static', keyboard: false });
  // this.guid=guid;
  // this.type =type
  this.dialogRef.close('Yes');
}

}
