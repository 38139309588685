import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { XpertsPageModule } from '../../../xperts-page/xperts-page.module';
import { faSave,faEraser,faPaperclip, faPhone, faAt, faClock, faUsd } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  model: any = {};
 
  currDate: Date = new Date()
  showAttachement: boolean = false;
  xpertsPageMode: number;

  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faclocko = faClock;
  fausd = faUsd;
  faat = faAt;
  faSave = faSave;
  faPhone=faPhone;
  faAt=faAt;
  badgeStatus: string;
  faEraser = faEraser;
  faPaperclip=faPaperclip;
  FileList:any=[];
  FileUploadData:any=[];

  EmployeeLogin:any=[];
  EmployeeTypeValue:any=[];
  State:any=[];
  CountryName:any=[];
  City:any=[] ;


  ProgressSpinner: boolean = false;

  constructor(private TranslateService: TranslateService,
    private authService: AuthService,
    private toastr: ToastrService) {
  }


  newForm() {
    this.model = {
  "EmployeeCode": "",
  "EmployeeFname": "",
  "EmployeeLname": "",
  "EmployeeEmp": "",
  "EmployeeEmpCode": "",
  "EmployeeType": "",
  "EmployeeLoginID": "",
  "EmployeeEmail": "",
  "EmployeeText": "",
  "GoogleCalender": "",
  "GoogleCalenderID": "",
  "PrntAddressLine1": "",
  "PrntAddressLine2": "",
  "PrntAddressLine3": "",
  "PrntCity": "",
  "PrntState": "",
  "PrntCountry": "",
  "PrntPostalCode": "",
  "PrntEmail": "",
  "PrntMobile": "",
  "PerAddressLine1": "",
  "PerAddressLine2": "",
  "PerAddressLine3": "",
  "PerCity": "",
  "PerState": "",
  "PerCountry": "",
  "PerPostalCode": "",
  "PerEmail": "",
  "PerMobile": "",
  "Status": "",
  "CreatedBy": null
    }

      this.badgeStatus = 'New';
    // this.isLoginAllow = false;
  }
  
  ngOnInit() {
    this.loadDropDown();
  }

  imgStatus(e) {
    this.model.ProfileImageEmpPhotoGUID = null;
    this.model.ProfileImageEmpPhotoURL = '';
    console.log("e",e);
  }

  Fileuploaded(e) {
    this.showAttachement = e.show;
    this.FileList = e.data;
    console.log(this.FileList)
  }


  loadDropDown() {
    this.authService.GetDropDownList('EmployeeLoginID').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.EmployeeLogin  = result.Dropdownlist
        }
      }
    })

    this.authService.GetDropDownList('EmployeeType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.EmployeeTypeValue = result.Dropdownlist
        }
      }
    })
    this.authService.GetDropDownList('State').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.State = result.Dropdownlist
        }
      }
    })

    this.authService.GetDropDownList('CourtCountry').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.CountryName = result.Dropdownlist
        }
      }
    })

    this.authService.GetDropDownList('City').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.City = result.Dropdownlist
        }
      }
    })

  }



  //
  //determines pagemode
  pageMode(mode) {
    this.xpertsPageMode = mode;
    this.newForm();
  }
 
  postForm(form: NgForm) {
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
   //this.model.EmployeeType="Tested";
    this.ProgressSpinner = true;
    this.authService.CrmPostJsonService(this.authService.masterApi, 'PostEmployee', this.model).subscribe(result => {
      this.ProgressSpinner = false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
        //  this.pageEditData();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
           this.xpertsPageMode = 1;
        }        
      }else {
        this.toastr.error(result.Response._Description, 'Error');
      }
    },(err)=>{
      this.ProgressSpinner=false;
    });
  }
  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "EmployeeCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetEmployee", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.Employeelists[0]);
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.Employeelists[0]);
          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.Employeelists[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.Employeelists[0]);
           
          }
    }
  }
  });
    
      }


  clear() {
    if (this.xpertsPageMode != 1) {
      this.badgeStatus = 'Edit';
    }

  }


  //checking file size
  filesize(size: any) {
    return (size.size / (1024 * 1024)).toFixed(2);
  }
 
  //image upload function
  postFile(e: any, option, url) {
    console.log("ee", e);
    console.log("option", option);
    console.log("url", url);
    this.FileUploadData = e.target.files[0];
    const formData: FormData = new FormData();
    formData.append("File", this.FileUploadData);
    formData.append("fileMode", "post");
    this.ProgressSpinner = true;
    this.model.UserName = this.model.Email;
    this.authService.PostFileUpload(this.authService.adminApi, 'PostSingleFileUpload', formData).subscribe(result => {
      this.ProgressSpinner = false;
     

      if (result !== null) {
        var result: any = result;
        if (result.Response._code == 111) {
          this.toastr.success(result.Response._Description);
          var rooturl = this.authService.baseUrl;
          var imurl = rooturl + '/' + result.RelativePath.replace(/\\/g, "/");
          this.toastr.success(result.Response._Description)
          this.model[option + 'GUID'] = result.FileGUID;
          console.log("this.model[option + 'GUID']", this.model[option + 'GUID']);
          this.model[option + 'URL'] = imurl;
          console.log("this.model[option + 'URL']", this.model[option + 'URL']);
          url = imurl;
        }
      }
    }, (err) => {
      this.ProgressSpinner = false;
    });
  }



  delImg(option, GUID) {
    let req = { 'AttachmentsGUID': GUID };
    this.authService.CrmPostJsonService(this.authService.adminApi, 'RemoveAttachment', req).subscribe(res => {
      let result: any = res;
      if (result !== null) {
        if (result.Response._code == '111') {
          this.model[option + 'Image'] = null
          this.model[option + 'URL'] = '';
          option = ''
        }
      }
    })
  }

  


}
