import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faSave, faEraser, faTrash, faEdit, faPaperclip, faPrint } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  model:any={};
  badgeStatus:any;
  commonSave: string = 'Common.save';
  faEraser = faEraser;
  faSave = faSave;
  faTrash = faTrash;
  faEdit = faEdit;
  faPaperclip = faPaperclip;
  faPrint = faPrint;
  icon: string = "fa fa-save";
  xpertsPageMode: number;
  ProgressSpinner: boolean = false;

  newForm(){
    this.model= {
      "ActCode": "",
      "ActName": "",
      "ActDesc": "",
      "Status": 'active'
    }; 
    this.badgeStatus='New';   
  }
  constructor(
    private authService: AuthService,private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  postForm(form: NgForm) {
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";

   
    this.ProgressSpinner = true;
    if(this.model.Status == true){
      this.model.Status = 'active'
    }
    else if(this.model.Status == false){
      this.model.Status = 'inactive'
    }
    this.authService.CrmPostJsonService(this.authService.masterApi, 'PostActivity', this.model).subscribe(result => {
      this.ProgressSpinner = false;
      
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._status, "Success");
        
          form.resetForm();
          // this.pageEditData();
           this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
           this.xpertsPageMode = 1;
        }        
      }else {
        this.toastr.error(result.Response._Description, 'Error');
      }
    },(err)=>{
      this.ProgressSpinner=false;
    });
  }

  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "ActCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetActivity", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.Activitylists);
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.Activitylists[0]);
           if(this.model.Status.toLowerCase() == 'active'){
            this.model.Status = true
          }
          else if(this.model.Status.toLowerCase() == 'inactive'){
            this.model.Status =  false
          }
          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.Activitylists[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.Activitylists[0]);
           
          }
    }
  }
  });
    
      }
  clear(){
    this.newForm();
  }

   //determines pagemode
   pageMode(mode) {
    this.xpertsPageMode = mode;
    this.newForm();
  }

  
}
