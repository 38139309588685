<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>
<app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup>
<!-- <app-confirmation-popup style="display: none;" #popup (imgStatus)="imgStatus($event)" (status)="status($event)">
</app-confirmation-popup> -->
<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode = 0;"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{ 'Employee1.heading' | translate }}
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">

                                <button class="btn btn-light mr-1" (click)="clear()" type="button"
                                    *ngIf="xpertsPageMode==1">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}</button>&nbsp;
                                <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                    class="btn btn-success pull-right mr-2" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}</button>

                                <button (click)="showAttachement = true" class="btn btn-primary pull-right mr-1"
                                    type="button">
                                   <fa-icon [icon]="faPaperclip" ></fa-icon>&nbsp; {{'master.Employee.label.attach' | translate}}
                                    <sup>
                                        <span *ngIf="FileList.length>0" class="badge badge-pill badge-info " style=" right: -16px;
                                            top: -13px;
                                            background: white;
                                            color: rebeccapurple;
                                            padding: 4px 6px;">{{FileList.length}}</span>

                                    </sup>

                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3" style="text-align: center;">
                                <div class="image-upload">
                                    <label for="file-input" style="margin-top: 6px;">
                                        <img src="{{model.EmpPhotoURL}}" onerror="this.src='assets/images/boy.jpg';"
                                            class="img-responsive"
                                            style="height:150px; border-radius:50%; width:155px;cursor: pointer;border: 6px solid #f8f8f8;">
                                        <div class="middle" *ngIf="!model.EmpPhotoURL">
                                            <div class="text">
                                                <img src="assets/images/cloud.png" width="45px">
                                            </div>
                                        </div>
                                    </label>
                                    <input style="display: none;" id="file-input" accept=".jpg, .png, .jpeg, .tiff"
                                        type="file" name="EmpPhotoGUID"
                                        (change)="postFile($event,'EmpPhoto',model.EmpPhoto)" />

                                    <img *ngIf="model.EmpPhotoURL" (click)="popup.open2()" src="assets/images/tr.png"
                                        width="20px" style="position: absolute;cursor: pointer; margin-top: 90px">
                                </div>

                                <!-- <div class="image-upload">
                                    <label for="file-input" style="margin-top: 100px;">
                                        <img src="{{model.ImageURL}}" onerror="this.src='assets/images/boy.jpg';"
                                            class="img-responsive"
                                            style="height:150px; border-radius:50%; width:155px;cursor: pointer;border: 6px solid #f8f8f8;">
                                        <div class="middle" *ngIf="!model.ImageURL">
                                            <div class="text">
                                                <img src="assets/images/cloud.png" width="30px">
                                            </div>
                                        </div>
                                    </label>
                                    <input style="display: none;" id="file-input" accept=".jpg, .png, .jpeg, .tiff"
                                        type="file" name="EmpPhotoGUID"
                                        (change)="postFile($event,'Image',model.EmpPhoto)" />

                                    <img *ngIf="model.ImageURL" (click)="popup.open2()" src="assets/images/tr.png"
                                        width="20px" style="position: absolute;cursor: pointer; margin-top: 90px">
                                </div> -->
                            </div>
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div class="row">
                                    <!-- Name -->


                                    <!-- Code -->
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.FirstName' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                             maxlength="75" name="firstname" #firstname="ngModel"
                                           [(ngModel)]="model.EmployeeFname"
                                            >
                                      
                                        <span *ngIf="formPage.submitted && !firstname.valid"
                                        class="invalid-tooltip">
                                        {{'Employee1.error.FirstName' | translate}}
                                    </span> 

                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.Lastname' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                             maxlength="75" name="lastname" #lastname="ngModel"
                                            [(ngModel)]="model.EmployeeLname"
                                             >
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->

                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.Fullname' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                             maxlength="75" name="fullname" #fullname="ngModel"
                                            [(ngModel)]="model.EmployeeEmp" required>
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !fullname.valid"
                                        class="invalid-tooltip">
                                        
                                        {{'Employee1.error.Fullname' | translate}}
                                    </span> 

                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.employeecode' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                             maxlength="75" name="empcode" #empcode="ngModel"
                                            [(ngModel)]="model.EmployeeEmpCode" 
                                             required>
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !empcode.valid"
                                        class="invalid-tooltip">
                                        
                                        {{'Employee1.error.employeecode' | translate}}
                                    </span> 

                                    </div>
                                 
                                   
                                    <!-- [hideSelected]="true"    [closeOnSelect]="false" multiple="true"  -->
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="picker1">{{'Employee1.label.type' | translate}}</label>

                                        
                                        <ng-select  [items]="EmployeeTypeValue" bindLabel="Value" bindValue="ID"
                                         style="border-radius: 25px;" class="required"
                                          name="EmployeeType" #EmployeeType="ngModel" [(ngModel)]="model.EmployeeType"  >
                                        </ng-select>
                                       
                                        <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg005' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !Type.valid"
                                        class="invalid-tooltip">
                                        
                                        {{'Employee1.error.type' | translate}}
                                    </span> 
                                    </div>


                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="picker1">{{'Employee1.label.loginid' | translate}}</label>

                                        

                                        <ng-select  [items]="EmployeeLogin" bindLabel="Value" bindValue="ID"
                                        style="border-radius: 25px;" 
                                          name="Type" #Type="ngModel" [(ngModel)]="model.EmployeeLoginID" >
                                        </ng-select>
                                        <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg005' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.Email' | translate}}</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                            name="Email" #Email="ngModel"
                                            [(ngModel)]="model.EmployeeEmail"
                                           
                                           required>
                                           <div class="icondiv">
                                            <fa-icon [icon]="faat" class="icon"></fa-icon>
                                        </div>

                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !Email.valid"
                                        class="invalid-tooltip">
                                        
                                        {{'Employee1.error.Email' | translate}}
                                    </span> 
                                    </div>
                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.employeetext' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                            maxlength="75" name="employeetext" #employeetext="ngModel"
                                            [(ngModel)]="model.EmployeeText"
                                           >
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->

                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>{{'Employee1.label.googlecalender' | translate}}</label>
                                        <input type="text" name="Registeron" [(ngModel)]="model.GoogleCalender"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" 
                                          
                                            #Registeron="ngModel"
                                            #dp="bsDatepicker" class="form-control " bsDatepicker>
                                        <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                            [attr.aria-expanded]="dp.isOpen"></i>

                                        <!-- <span *ngIf="formPage.submitted && !DOJ.valid" class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg024' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                        <label for="txtEmployeeCode">{{'Employee1.label.googlecalenderId' | translate}}</label>
                                        <input class="form-control form-control-rounded " type="text"
                                             maxlength="75" name="calenderid" #calenderid="ngModel"
                                            [(ngModel)]="model.GoogleCalenderID"
                                            >
                                        <!-- <span
                                            *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg003' | translate }}
                                        </span>
                                        <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                            class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg031' | translate }}
                                        </span> -->

                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="col-md-12 mb-4">
                            <div class="card text-left">
                                <form-wizard>
                                    <wizard-step [title]="'Present Address'">
                                        <div class="row col-lg-12 pt-3">
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline1' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75" name="address1" #address1="ngModel"
                                                    [(ngModel)]="model.PrntAddressLine1"
                                                     >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline2' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                 maxlength="75" name="Address2" #Address2="ngModel"
                                                    [(ngModel)]="model.PrntAddressLine2"
                                                    >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline3' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                  maxlength="75" name="Address3" #Address3="ngModel"
                                                    [(ngModel)]="model.PrntAddressLine3"
                                                     >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.city' | translate}}</label>

                                              

                                                <ng-select [items]="City" bindLabel="Value" bindValue="ID"
                                                style="border-radius: 25px;"
                                                  name="city1" #city1="ngModel"   [(ngModel)]="model.PrntCity">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.State' | translate}}</label>

                                                <ng-select [items]="State" bindLabel="Value" bindValue="ID" 
                                                style="border-radius: 25px;"
                                                  name="state1" #state1="ngModel" [(ngModel)]="model.PrntState">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.Country' | translate}}</label>
                                                <ng-select [items]="CountryName" bindLabel="Value" bindValue="ID" 
                                                 style="border-radius: 25px;"
                                                  name="country1" #country1="ngModel" [(ngModel)]="model.PrntCountry">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.postalcode' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75" name="Postalco" #Postalco="ngModel"
                                                    [(ngModel)]="model.PrntPostalCode"
                                                    >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Email' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                name="Email12" #Email12="ngModel"
                                                    [(ngModel)]="model.PrntEmail"
                                                    (keypress)="authService._restrictspecialchar($event)" >
                                                    <div class="icondiv">
                                                        <fa-icon [icon]="faat" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Mobile' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                   maxlength="75" name="Mobile" #Mobile="ngModel"
                                                    [(ngModel)]="model.PrntMobile"
                                                    >
                                                    <div class="icondiv">
                                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                    </div>
    
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                         

                                        </div>
                                    </wizard-step>
                                    <wizard-step [title]="'Permanent Address'">
                                        <div class="row col-lg-12 pt-3">
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline1' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                   maxlength="75" name="address11" #address11="ngModel"
                                                    [(ngModel)]="model.PerAddressLine1"
                                                     >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline2' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75" name="Address22" #Address22="ngModel"
                                                    [(ngModel)]="model.PerAddressLine2"
                                                    >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Addressline3' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75" name="Address33" #Address33="ngModel"
                                                    [(ngModel)]="model.PerAddressLine3"
                                                   >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.city' | translate}}</label>
                                                <ng-select [items]="City" bindLabel="Value" bindValue="ID" 
                                                 style="border-radius: 25px;"
                                                  name="city" #city="ngModel" [(ngModel)]="model.PerCity">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.State' | translate}}</label>
                                                <ng-select [items]="State" bindLabel="Value" bindValue="ID" 
                                                 style="border-radius: 25px;"
                                                  name="state" #state="ngModel"  [(ngModel)]="model.PerState">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="picker1">{{'Employee1.label.Country' | translate}}</label>
                                                <ng-select [items]="CountryName" bindLabel="Value" bindValue="ID" 
                                                 style="border-radius: 25px;"
                                                  name="Country" #Country="ngModel" [(ngModel)]="model.PerCountry">
                                                </ng-select>
                                                <!-- <span *ngIf="formPage.submitted && !location.valid" class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg005' | translate }}
                                                </span> -->
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.postalcode' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                     maxlength="75" name="postal" #postal="ngModel"
                                                    [(ngModel)]="model.PerPostalCode"
                                                    >
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Email' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                name="Email1" #Email1="ngModel"
                                                    [(ngModel)]="model.PerEmail"
                                                    >
                                                    <div class="icondiv">
                                                        <fa-icon [icon]="faat" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                            <div class="col-sm-3 col-md-3 col-lg-3 form-group mb-3">
                                                <label for="txtEmployeeCode">{{'Employee1.label.Mobile' | translate}}</label>
                                                <input class="form-control form-control-rounded " type="text"
                                                   maxlength="75" name="Mobile1" #Mobile1="ngModel"
                                                    [(ngModel)]="model.PerMobile"
                                                     >
                                                     <div class="icondiv">
                                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                                    </div>
                                                <!-- <span
                                                    *ngIf="formPage.submitted && !txtEmployeeCode.valid && !model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg003' | translate }}
                                                </span>
                                                <span *ngIf="formPage.submitted && !txtEmployeeCode.valid && model.EmployeeCode"
                                                    class="invalid-tooltip">
                                                    {{ 'master.Employee.message.msg031' | translate }}
                                                </span> -->
        
                                            </div>
                                         

                                        </div>
                                    </wizard-step>

                                   
                                </form-wizard>
                            </div>
                        </div>


                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">

                                    <div class="col-lg-12" style="text-align: end;" >
                                        <button class="btn btn-light pull-right mr-1" (click)="clear()" type="button">
                                            <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                            }}</button>&nbsp;
                                        <button 
                                            class="btn btn-success pull-right mr-1" type="submit">
                                            <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <p >Branch: {{d1|ddIndexValue:llist}}</p> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>