<!-- <app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page> -->
<!-- <app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup> -->
<!-- <app-confirmation-popup style="display: none;" #popup (imgStatus)="imgStatus($event)" (status)="status($event)">
</app-confirmation-popup> -->

<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>



<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode=0"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;Calender
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;">

                                <button class="btn btn-light mr-1" type="button">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button class="btn btn-success pull-right mr-2" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}
                                </button>


                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="row">
                                    <!-- Name -->
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Date</label>
                                        <input type="text" name="doj" [(ngModel)]="model.EventCalEvtDt"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"  
                                            #doj="ngModel" #dp="bsDatepicker" class="form-control requiredIn"
                                            bsDatepicker>
                                        <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                            [attr.aria-expanded]="dp.isOpen"></i>

                                        <span *ngIf="formPage.submitted && !doj.valid" class="invalid-tooltip">
                                            Please Select the Date
                                        </span>
                                    </div>
                                    

                                    <!-- Code -->
                                    <!-- <div  class="col-md-3 form-group mb-3">
                                        <label for="CategoryGUID">Agent</label>
                                        <ng-select bindLabel="Value" bindValue="ID" [(ngModel)]="model.Casetype"
                                         name="CountryGUID" #ddlCountryGUID="ngModel" class="required"
                                     >
                                        </ng-select>
                                        <span *ngIf="formPage.submitted && !ddlCountryGUID.valid"  class="invalid-tooltip">
                                            {{'master.State.message.msg002' | translate }}
                                        </span>
                                    </div> -->
                                    <div class="col-md-3 form-group mb-3">
                                        <label for="CategoryGUID">Agent</label>
                                        <ng-select [items]="agent" [(ngModel)]="model.EventCalAgentID" 
                                        bindLabel="Value" bindValue="ID" name="Agent" #Agent="ngModel" class="required">
                                        </ng-select>
                                        <span *ngIf="formPage.submitted && !Agent.valid" class="invalid-tooltip">
                                            Please Select the Agent
                                        </span>
                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Client Id</label>
                                        <ng-select [items]="clientid" (search)="getDropdownClientID($event)" bindLabel="Value" bindValue="ID" [(ngModel)]="model.EventCalClientID" name="Client"
                                            #Client="ngModel" class="required">
                                        </ng-select>
                                        <span *ngIf="formPage.submitted && !Client.valid" class="invalid-tooltip">
                                            Please Select the Client
                                        </span>
                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Matter Id</label>
                                        <ng-select [items]="matterid" (search)="getDropdownMatterID($event)"  bindLabel="Value" bindValue="ID" [(ngModel)]="model.EventCalMatterID" name="Matter"
                                            #Matter="ngModel" class="required">
                                        </ng-select>
                                        <span *ngIf="formPage.submitted && !Matter.valid" class="invalid-tooltip">
                                            Please Enter the Matter Id
                                        </span>
                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Title</label>
                                        <input class="form-control form-control-rounded requiredIn" type="text"
                                            maxlength="75" name="Title" #Title="ngModel" [(ngModel)]="model.EventCalEvtTitle" >

                                        <span *ngIf="formPage.submitted && !Title.valid" class="invalid-tooltip">
                                            Please Enter the Title
                                        </span>
                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Event Type</label>
                                        <ng-select [items]="eventtype" bindLabel="Value" bindValue="ID" [(ngModel)]="model.EventCalEvtTyp" name="Etype"
                                            #Etype="ngModel" >
                                        </ng-select>
                                        <span *ngIf="formPage.submitted && !Etype.valid" class="invalid-tooltip">
                                            Please Enter the Event Type
                                        </span>
                                    </div>
                                    <div class="col-md-3 form-group mb-3">
                                        <label>Scheduled Date</label>
                                        <input type="text" name="doj12" [(ngModel)]="model.EventCalSchDt"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }"  #doj12="ngModel"
                                            #dp1="bsDatepicker" class="form-control " bsDatepicker>
                                        <i class="i-Calendar calenderStyle" (click)="dp1.toggle()"
                                            [attr.aria-expanded]="dp1.isOpen"></i>

                                        <!-- <span *ngIf="formPage.submitted && !DOJ.valid" class="invalid-tooltip">
                                            {{ 'master.Employee.message.msg024' | translate }}
                                        </span> -->
                                    </div>
                                    <!-- <div class="col-md-3 form-group mb-3">
                                        <label for="CategoryGUID">Scheduled Time</label>
                                        <input class="form-control form-control-rounded " [ngxTimepicker]="picker"
                                            name="scheduled" #scheduled="ngModel" [(ngModel)]="model.EventCalSchtim">
                                            <div class="email" style="margin-left: 91%; margin-top: -33px;"
                                            (click)="picker.open()" type="button">
                                            <div style="margin-top: -34px;
                                            line-height: 33px;
                                            margin-left: -25%;">
                                                <fa-icon [icon]="faclocko" style="color: darkgray;"></fa-icon>
                                            </div>
                                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                                        </div>
                                        <span *ngIf="formPage.submitted && !scheduled.valid" class="invalid-tooltip">
                                            Please Select Scheduled Time
                                        </span>

                                    </div> -->

                                    <div class="col-md-3 form-group mb-3">
                                        <label for="CategoryGUID">Scheduled Time</label>
                                        <input [ngxTimepicker]="aStimePicker" class="form-control form-control-rounded"
                                            placeholder="hh:mm" name="aStimePicker" [format]="24"
                                            [(ngModel)]="model.EventCalSchtim" #startsOn="ngModel">
                                        <div class="email" style="margin-left: 91%; margin-top: -33px;"
                                            (click)="picker.open()" type="button">
                                            <div style="margin-top: -34px;
                                                line-height: 33px;
                                                margin-left: -25%;">
                                                <fa-icon [icon]="faclocko" style="color: darkgray;"></fa-icon>
                                            </div>
                                            <ngx-material-timepicker #aStimePicker></ngx-material-timepicker>
                                        </div>



                                    </div>


                                    <!-- <div class="col-md-3 form-group mb-3">
                                        <label>Duration(Min)</label>
                                        <input class="form-control form-control-rounded " [ngxTimepicker]="picker1"
                                            name="Duration" #Duration="ngModel" [(ngModel)]="model.EventCalSchDur">
                                            <div class="email" style="margin-left: 91%; margin-top: -33px;"
                                            (click)="picker1.open()" type="button">
                                            <div style="margin-top: -34px;
                                            line-height: 33px;
                                            margin-left: -25%;">
                                                <fa-icon [icon]="faclocko" style="color: darkgray;"></fa-icon>
                                            </div>
                                            <ngx-material-timepicker #picker1></ngx-material-timepicker>
                                        </div>
                                        <span *ngIf="formPage.submitted && !scheduled.valid" class="invalid-tooltip">
                                            Please Select Scheduled Time
                                        </span>

                                    </div> -->

                                    <div class="col-md-3 form-group mb-3">
                                        <label>Duration(Min)</label>
                                        <input class="form-control form-control-rounded " type="text"
                                            maxlength="75" name="duration" #duration="ngModel" [(ngModel)]="model.EventCalSchDur" >

                                        <!-- <span *ngIf="formPage.submitted && !Title.valid" class="invalid-tooltip">
                                            Please Enter the Title
                                        </span> -->
                                    </div>
                                   
                                    <div class="col-md-3 form-group mb-3">
                                        <label for="CategoryGUID">Event Catagory</label>
                                        <ng-select [items]="eventcatagory" bindLabel="Value" bindValue="ID" name="Event" #Event="ngModel"
                                        [(ngModel)]="model.EventCalCate" class="required">
                                        </ng-select>
                                        <!-- <span *ngIf="formPage.submitted && !ddlCountryGUID.valid"  class="invalid-tooltip">
                                            {{'master.State.message.msg002' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-md-3 form-group mb-3">
                                        <label>Event Location</label>
                                        <input class="form-control form-control-rounded " type="text"
                                            maxlength="75" name="Location" #Location="ngModel" [(ngModel)]="model.EventCalLoc" >
                                        <!-- <span *ngIf="formPage.submitted && !txtPresAddress1.valid"
                                            class="invalid-tooltip">
                                       
                                            {{ 'Please Enter the Door No' | translate }}
                                        </span> -->
                                    </div>




                                    <div class="col-lg-12 mb-3">
                                        <label>Notes</label>

                                        <textarea type="text" class="form-control form-control-rounded" name="Note"
                                            #Note="ngModel" rows="5" cols="120" [(ngModel)]="model.EventCalNote" maxlength="250">

                                            </textarea>

                                    </div>



                                </div>

                            </div>
                        </div>




                    </div>
                    <div class="card-footer">
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="row">

                                    <div class="col-lg-12" style="text-align: end;">
                                        <button class="btn btn-light pull-right mr-1" type="button">
                                            <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                            }}
                                        </button>&nbsp;
                                        <button class="btn btn-success pull-right mr-1" type="submit">
                                            <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{
                                            commonSave | translate }}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <p >Branch: {{d1|ddIndexValue:llist}}</p> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="lds-roller" >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div> -->

</div>