import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faSave, faEraser, faTrash, faEdit, faPaperclip, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-refrs',
  templateUrl: './refrs.component.html',
  styleUrls: ['./refrs.component.scss']
})
export class RefrsComponent implements OnInit {

  model:any={};
  badgeStatus:any;
  commonSave: string = 'Common.save';
  faEraser = faEraser;
  faSave = faSave;
  faTrash = faTrash;
  faEdit = faEdit;
  faPaperclip = faPaperclip;
  faPrint = faPrint;
  xpertsPageMode: number;
  
  newForm(){
    this.model= {
      "ReferenceCode": "",
      "ReferenceName": "",
      "ReferenceDesc": "",
      "Status": "InActive"
    }; 
    this.badgeStatus='New';   
  }
  constructor(public authService: AuthService, private toastr: ToastrService) { }


  ngOnInit(): void {
    
  }
  postForm(form: NgForm) {
   // this.sumitChanges = true;
    this.commonSave = 'Common.process';
  //  this.icon = "fa fa-spinner fa-spin";
    this.authService.CrmPostJsonService(this.authService.EnquiryApi, 'PostReference', this.model).subscribe(result => {

      if (result !== null) {
      //  this.sumitChanges = false;
        this.commonSave = 'Common.save';
       // this.icon = "fa fa-save";
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");

          form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
          this.xpertsPageMode = 1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }

  pageEditData(pageData : any) {
    let req = { 'ReferenceCode': pageData[0] };

    this.authService.CrmGetJsonService(this.authService.EnquiryApi, "GetReference", req).subscribe(result => {

      if (result !== null) {
        if (result.Response._code == "111") {

          this.model = Object.assign({}, result.ReferenceList[0]);
          if (this.xpertsPageMode == 2) {
            this.badgeStatus = 'Edit';
          } else if (this.xpertsPageMode == 4) {
            this.badgeStatus = 'View';
          } else if (this.xpertsPageMode == 3) {
           // this.badgeStatus = 'Copy';
            // this.model.BankGUID = UUID.UUID();
          }

        } else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }
  clear(){
    this.newForm();
  }

   //determines pagemode
   pageMode(mode) {
    this.xpertsPageMode = mode;
    this.newForm();
  }

}
