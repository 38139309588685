import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faSave, faEraser, faTrash, faEdit, faPaperclip, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit {
  model:any={};
  badgeStatus:any;
  commonSave: string = 'Common.save';
  faEraser = faEraser;
  faSave = faSave;
  faTrash = faTrash;
  faEdit = faEdit;
  faPaperclip = faPaperclip;
  faPrint = faPrint;
  xpertsPageMode: number;
  icon: string = 'fa fa-save';
  ProgressSpinner:boolean;
  Status:string;


  
  newForm(){
    this.model= {
      "CityCode": "",
      "CityOUCode": null,
      "CityName": "",  
      "CityDesc": "",
      "Status":"",
    }; 
    this.badgeStatus='New'; 
  
      
  }
  constructor(private authService: AuthService, private toastr: ToastrService) { }



  ngOnInit(): void {
  

    }
  
  postForm(form: NgForm){   
    // this.model.StateCode="NEWID";
  
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner=true;
    this.authService.CrmPostJsonService(this.authService.masterApi,'PostCity',this.model).subscribe(result => {
      this.ProgressSpinner=false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      console.log("this.model",this.model);
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
        //  form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222"){
          this.toastr.warning(result.Response._Description,"Warning");
          this.xpertsPageMode=1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        } 
      }
    });
  }
 
      

      pageEditData(pageData) {
        const req = { "CityCode": pageData[0] }
        this.authService.CrmGetJsonService(this.authService.masterApi, "GetCity", req).subscribe(result => {
          console.log("result===>", result)
          if (result !== null) {
            if (result.Response._code == "111") {
            
             if(this.xpertsPageMode ==2){
              this.badgeStatus = 'Edit';
             }
             else if(this.xpertsPageMode ==4){
              this.badgeStatus = 'View';
             }
             else if(this.xpertsPageMode ==3){
              this.badgeStatus = 'Copy';
             }
                this.model = Object.assign({}, result.City[0]);
                this.toastr.success(result.Response._Description);
            } 
            else{this.toastr.error(result.Response._Description,'Error')}
          }
        })
      }
  //determines pagemode
  pageMode(mode) {
    this.xpertsPageMode = mode;
    this.newForm();
  }
  clear(){
    this.newForm();
  }

}
