import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faSave, faEraser, faTrash, faEdit, faPaperclip, faPrint } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-event-type',
  templateUrl: './event-type.component.html',
  styleUrls: ['./event-type.component.scss']
})
export class EventTypeComponent implements OnInit {
  model:any={};
  badgeStatus:any;
  commonSave: string = 'Common.save';
  faEraser = faEraser;
  faSave = faSave;
  faTrash = faTrash;
  faEdit = faEdit;
  faPaperclip = faPaperclip;
  faPrint = faPrint;
  xpertsPageMode: number;
  icon: string = 'fa fa-save';
  ProgressSpinner:boolean;
  constructor(private authService: AuthService, private toastr: ToastrService) { }

  newForm(){
    this.model= {
    "EventTypeCode": "",
    "EventTypeOUCode": "",
    "EventTypeName": "",  
    "EventTypeDesc": "",
    "EventTypeEcod": "",
    "Status": ""
    }; 
    this.badgeStatus='New';   
  }
  ngOnInit(): void {
  }
  postForm(form: NgForm){   
    // this.commonSave = 'Common.process';
    // this.icon = "fa fa-spinner fa-spin";
    // this.ProgressSpinner=true;
    this.authService.CrmPostJsonService(this.authService.masterApi,'PostEventType',this.model).subscribe(result => {
      // this.ProgressSpinner=false;
      // this.commonSave = 'Common.save';
      // this.icon = "fa fa-save";
      console.log("this.model",this.model);
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222"){
          this.toastr.warning(result.Response._Description,"Warning");
          this.xpertsPageMode=1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        } 
      }
    });
  }

  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "EventTypeCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetEventType", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.EventType);
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.EventType[0]);
          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.EventType[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.EventType[0]);
           
          }
    }
  }
  });
    
      }


  clear(){
    this.newForm();
   
  }


    //determines pagemode
    pageMode(mode) {
      this.xpertsPageMode = mode;
      this.newForm();
    }

}
