import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { AuthService } from '../../../shared/services/auth.service';
import { faSave,faEraser } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.scss']
})
export class SourceComponent implements OnInit {

  xpertsPageMode: number;
  badgeStatus: string;
  sumitChanges: boolean = false;
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faEraser=faEraser;
  faSave = faSave;
  model: any = {};
  loggedby: any;

  constructor(public authService: AuthService, private toastr: ToastrService) { 
    // setTimeout(() => {
    //   this.loggedby = JSON.parse(sessionStorage.getItem('currentUser'));
    //   this.ngForm();
    // });
  }

  ngOnInit(): void {
  }
  pageMode(mode) {
    this.xpertsPageMode = mode;
    this.ngForm();
    this.badgeStatus = 'New';
  }

  //form variables declaration
  ngForm() {
    this.model = {
      "SourceCode": "",
      "SourceName": "",
      "SourceDesc": "",
      "Status": ""

    }
  }

 //Post function
  postForm(form: NgForm) {
    this.sumitChanges = true;
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    this.authService.CrmPostJsonService(this.authService.EnquiryApi, 'PostSource', this.model).subscribe(result => {

      if (result !== null) {
        this.sumitChanges = false;
        this.commonSave = 'Common.save';
        this.icon = "fa fa-save";
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");

          form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
          this.xpertsPageMode = 1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }

  clear(){
    this.ngForm();
   
  }
  //Edit the form
  pageEditData(pageData : any) {
    let req = { 'SourceCode': pageData[0] };

    this.authService.CrmGetJsonService(this.authService.EnquiryApi, "GetSource", req).subscribe(result => {

      if (result !== null) {
        if (result.Response._code == "111") {
          this.model = Object.assign({}, result.SourceList[0]);
          if (this.xpertsPageMode == 2) {
            this.badgeStatus = 'Edit';
          
          } else if (this.xpertsPageMode == 4) {
            this.badgeStatus = 'View';
          } else if (this.xpertsPageMode == 3) {
           // this.badgeStatus = 'Copy';
            // this.model.BankGUID = UUID.UUID();
          }

        } else {
          this.toastr.error(result.Response._Description, 'Error');
        }
      }
    });
  }

}
