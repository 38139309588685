<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>

<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode=0"
                                    type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;Source
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;">

                                <button class="btn btn-light mr-1" (click)="clear()"  type="button">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button class="btn btn-success pull-right mr-2" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}
                                </button>


                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="name">{{ 'master.leadsource.label.sourcename' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" maxlength="50"
                                
                                  name="sourcename"
                                    #sourcename="ngModel" [(ngModel)]="model.SourceName" required>
                                <!-- <span *ngIf="formPage.submitted && !sourcename.valid && !model.LeadSourceName"
                                    class="invalid-tooltip">
                                    {{ 'master.leadsource.message.msg001' | translate }}
                                </span>
                                <span *ngIf="formPage.submitted && sourcename.errors?.minlength"
                                    class="invalid-tooltip">
                                    {{ 'master.leadsource.message.msg003' | translate }}
                                </span> -->
                            </div>
                            <!-- <div class="col-lg-6">
                                <label for="name">{{ 'master.leadsource.label.sourcesequence' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" type="text"
                                    placeholder="{{ 'master.leadsource.label.sourcesequence' | translate }}"
                                    name="sourcesequence" #sourcesequence="ngModel" [customMax]="maxNum" maxlength="250"
                                    (keypress)="authService._restrictNumbers($event)" pattern="[0-9]+"
                                    [(ngModel)]="model.LeadSourceSequence" required>

                              
                            </div> -->
                            <div class="col-lg-6">
                                <label for="name">{{ 'master.leadsource.label.sourcedescription' | translate }}</label>
                                <input class="form-control form-control-rounded"  name="sourcedescription" #sourcedescription="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="150" [(ngModel)]="model.SourceDesc"> 

                            </div>
                        </div>
                    </div>
                    <div class="card-footer" style="padding:10px 20px 10px 15px;">
                        <div class="row">
                            <div class="col-md-6 pl-4 mt-3">
                                <div class="row">

                                    <label class="switch">
                                        <input name="ch" type="checkbox" [(ngModel)]="model.Status">
                                        <span class="slider round"></span>
                                    </label>
                                    <span *ngIf="model.Status==true">{{'Common.active' | translate }}</span>
                                    <span *ngIf="model.Status==false">{{'Common.inActive' | translate }}</span>
                                </div>
                            </div>
                            <!-- <div class="col-md-6" style="padding: 0px 10px 0px 0px;" style="text-align: right;"
                                *ngIf="xpertsPageMode!=4">
                                <button class="btn btn-raised btn-raised-secondary m-1 pd-x-20 pull-right"
                                    (click)="ngForms()" type="submit">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                    class="btn btn-success pd-x-20 pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave"></fa-icon>&nbsp;{{ commonSave | translate }}
                                </button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>