import { Component, OnInit } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { AuthService } from '../../../shared/services/auth.service';
import { NgForm} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { faSave,faEraser,faPaperclip, faPhone, faAt, faClock, faUsd } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  xpertsPageMode: number;
  badgeStatus: string;
  Employeedd: any;
  commonSave: string = 'Common.save';
  icon: string = "fa fa-save";
  faclocko = faClock;
  fausd = faUsd;
  faat = faAt;
  faSave = faSave;
  faPhone=faPhone;
  faEraser = faEraser;
  faPaperclip=faPaperclip;
  model: any={};
  FileList:any=[];
  showAttachement: boolean = false;
  ProgressSpinner:boolean=false;
 
  
  constructor(public authService: AuthService, private toastr: ToastrService,private TranslateService: TranslateService,) { 

    // setTimeout(() => {
    //   this.ngForm()
    //   let d = JSON.parse(sessionStorage.getItem('selectedMenu'));
    //   if (d.Insert == 'True') {
    //     this.pageMode(1);
    //   } else if (d.Insert == 'False') {
    //     this.pageMode(0);
    //   }
    // }, 300);
  }

  ngOnInit() {

  }
  pageMode(mode) {
    this.xpertsPageMode = mode;
    this.badgeStatus = 'New';
    this.loadDropdown();
    this.ngForm();
  }

  ngForm(){
    this.model={

        "ClientCode": "",
        "RegisteredOn": "",
        "Salution": "",
        "FirstName": "",
        "MiddleName": "",
        "LastName": "",
        "FullName": "",
        "ClientID": "",
        "ClientPrefix": "",
        "IsAccountPosted": "",
        "SpouseName": "",
        "SpouseEmail": "",
        "SpousePhone": "",
        "PrimaryAdd1": "",
        "PrimaryAdd2": "",
        "PrimaryAdd3": "",
        "PrimaryCity": "",
        "PrimaryState": "",
        "PrimaryCountry": "",
        "PrimaryPostalCode": "",
        "PrimaryEmail": "",
        "PrimaryMobile": "",
        "PrimaryFax": "",
        "PrimaryHomePhone": "",
        "PrimaryWorkPhone": "",
        "SecondaryAdd1": "",
        "SecondaryAdd2": "",
        "SecondaryAdd3": "",
        "SecondaryCity": "",
        "SecondaryState": "",
        "SecondaryCountry": "",
        "SecondaryPostalCode": "",
        "SecondaryEmail": "",
        "SecondaryMobile": "",
        "SecondaryFax": "",
        "SecondaryHomePhone": "",
        "SecondaryWorkPhone": "",
        "Status": true
      
    }
  }
//Common dropdown
  loadDropdown() {

  }

  Fileuploaded(e) {
    this.showAttachement = e.show;
    this.FileList = e.data;
    console.log(this.FileList)
  }
  clear(){
   this.ngForm();
  }
  // Post and update function
  postForm(form: NgForm) {
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    this.ProgressSpinner = true;
 
    this.authService.CrmPostJsonService(this.authService.masterApi, 'PostClient', this.model).subscribe(result => {
      this.ProgressSpinner = false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
        //  this.pageEditData();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
           this.xpertsPageMode = 1;
        }        
      }else {
        this.toastr.error(result.Response._Description, 'Error');
      }
    },(err)=>{
      this.ProgressSpinner=false;
    });
  }
  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "ClientCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetClient", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.Clientlists[0]);
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.Clientlists[0]);
          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.Clientlists[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.Clientlists[0]);
           
          }
    }
  }
  });
    
      }

}
