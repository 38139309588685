<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>
<!-- <app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup> -->
<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-outline-primary pd-x-20" (click)="xpertsPageMode = 0;back()" type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp;{{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;County
                                    <span class="badge badge-primary">{{badgeStatus}}</span>
                                </span>
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">
                              
                                <button class="btn btn-light" (click)="clear()"
                                   type="submit">
                                  <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                }}</button>&nbsp;
                                <button
                                    class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-4">
                                <label>County</label>
                                <input class="form-control form-control-rounded requiredIn" type="text"
                                    
                                     maxlength="25"
                                    name="cou" #cou="ngModel" [(ngModel)]="model.county"
                                     required>
                                <!-- <span *ngIf="formPage.submitted && !txtCountryName.valid && !model.CountryName"
                                     class="invalid-tooltip">
                                    {{ 'master.Country.message.msg001' | translate }}
                                </span>
                                <span *ngIf="formPage.submitted && !txtCountryName.valid && model.CountryName"
                                    class="invalid-tooltip">
                                    {{ 'master.Country.message.msg002' | translate }}
                                </span> -->

                            </div>

                            <div class="col-lg-4">
                                <label>State</label>
                                <ng-select bindLabel="Value" bindValue="ID" [(ngModel)]="model.state"
                                name="sta" #sta="ngModel">
                            </ng-select>
                                <!-- <span *ngIf="formPage.submitted && !lastName.valid && !model.LastName"
                                    class="invalid-tooltip">
                                  
                                    {{ 'Please Enter the Last Name' | translate }}
                                </span> -->

                            </div>
   
                            <div class="col-lg-4">
                                <label >Description</label>
                                <input class="form-control form-control-rounded" type="text" maxlength="150"
                                   name="desc"
                                    #desc="ngModel" [(ngModel)]="model.description">
                                 


                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-md-6 pl-4 pt-2">
                                <div class="row">

                                    <label class="switch">
                                        <input name="ch"  type="checkbox" [(ngModel)]="model.Status" >
                                        <span class="slider round"></span>
                                    </label>
                                    <!-- <p>{{model.Status}}</p> -->
                                    <!-- <span *ngIf="model.Status==='active'">Active</span>
                                    <span *ngIf="model.Status==='inactive'">InActive</span> -->
                                    <span>{{model.Status === 'active'?'Active':'Inactive'}}</span> 
                                  
                                </div>
                            </div>
                            <!-- <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">
                              
                                <button class="btn btn-light" (click)="clear()"
                                  *ngIf="xpertsPageMode==1"  type="submit">
                                  <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                }}</button>&nbsp;
                                <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                    class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave"  style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave | translate }}
                                </button>
                            </div> -->


                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
</div>