<div class="breadcrumb">
    <h1>Buttons</h1>
    <ul>
        <li><a href="">UI Kits</a></li>
        <li>Buttons</li>
    </ul>
</div>

<div class="separator-breadcrumb border-top"></div>

<div class="row">
    <div class="col-md-12">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">Buttons</div>
                <button type="button" class="btn btn-primary m-1">Primary</button>
                <button type="button" class="btn btn-secondary m-1">Secondary</button>
                <button type="button" class="btn btn-success m-1">Success</button>
                <button type="button" class="btn btn-danger m-1">Danger</button>
                <button type="button" class="btn btn-warning m-1">Warning</button>
                <button type="button" class="btn btn-info m-1">Info</button>
                <button type="button" class="btn btn-light m-1">Light</button>
                <button type="button" class="btn btn-dark m-1">Dark</button>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">Buttons Rounded</div>
                <button type="button" class="btn btn-primary btn-rounded m-1">Primary</button>
                <button type="button" class="btn btn-secondary btn-rounded m-1">Secondary</button>
                <button type="button" class="btn btn-success btn-rounded m-1">Success</button>
                <button type="button" class="btn btn-danger btn-rounded m-1">Danger</button>
                <button type="button" class="btn btn-warning btn-rounded m-1">Warning</button>
                <button type="button" class="btn btn-info btn-rounded m-1">Info</button>
                <button type="button" class="btn btn-light btn-rounded m-1">Light</button>
                <button type="button" class="btn btn-dark btn-rounded m-1">Dark</button>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">
                    Buttons outline
                </div>
                <button type="button" class="btn btn-outline-primary m-1">Primary</button>
                <button type="button" class="btn btn-outline-secondary m-1">Secondary</button>
                <button type="button" class="btn btn-outline-success m-1">Success</button>
                <button type="button" class="btn btn-outline-danger m-1">Danger</button>
                <button type="button" class="btn btn-outline-warning m-1">Warning</button>
                <button type="button" class="btn btn-outline-info m-1">Info</button>
                <button type="button" class="btn btn-outline-dark m-1">Dark</button>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">
                    Button group
                </div>
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <div class="btn-group btn-group-toggle">
                        <label class="btn-primary" ngbButtonLabel>
                            <input type="checkbox" ngbButton [(ngModel)]="btnGroupModel.left"> Left (pre-checked)
                        </label>
                        <label class="btn-primary" ngbButtonLabel>
                            <input type="checkbox" ngbButton [(ngModel)]="btnGroupModel.middle"> Middle
                        </label>
                        <label class="btn-primary" ngbButtonLabel>
                            <input type="checkbox" ngbButton [(ngModel)]="btnGroupModel.right"> Right
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">Button block</div>
                <button type="button" class="btn btn-primary btn-block m-1">Primary</button>
                <button type="button" class="btn btn-success btn-block m-1">Success</button>
                <button type="button" class="btn btn-warning btn-block m-1">Warning</button>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="card mb-3">
            <div class="card-body">
                <div class="card-title">Big & Small Buttons</div>
                <div class="mb-3">
                    <button type="button" class="btn btn-primary btn-sm m-1">Primary</button>
                    <button type="button" class="btn btn-success btn-sm m-1">Success</button>
                    <button type="button" class="btn btn-warning btn-sm m-1">Warning</button>
                </div>
                <div>
                    <button type="button" class="btn btn-lg btn-primary m-1">Primary</button>
                    <button type="button" class="btn btn-success btn-lg m-1">Success</button>
                    <button type="button" class="btn btn-warning btn-lg m-1">Warning</button>
                </div>
            </div>
        </div>
    </div>

</div>