<app-xperts-page *ngIf="xpertsPageMode == 0" (pageMode)="pageMode($event)" (pageEditData)="pageEditData($event)">
</app-xperts-page>
<app-attachment-popup *ngIf="showAttachement" (uploaded)="Fileuploaded($event)" [InputData]="FileList">
</app-attachment-popup>
<div class="animated fadeIn" *ngIf="xpertsPageMode != 0">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 pr-2">
            <form name="formPage" (ngSubmit)="formPage.form.valid && postForm(formPage)" #formPage="ngForm" novalidate>
                <div class="card">
                    <div class="card-header" style="padding: 0.3rem 1.25rem">
                        <div class="row">
                            <div class="col-md-6" style="padding: 10px 0px 0px 10px;">
                                <button class="btn btn-light pd-x-20" (click)="xpertsPageMode = 0;" type="button">
                                    <i class="i-Arrow-Back-3"></i>&nbsp; {{ 'Common.back' | translate }}
                                </button>
                                <i class="i-Monitor-2 ml-2"></i>
                                <span style="font-size: 20px">&nbsp;&nbsp;{{ 'client1.heading' | translate }} 
                                    <span class="badge badge-primary" style="vertical-align: top; font-size: 10px;" >{{badgeStatus}}</span>  
                                </span>
                               
                            </div>
                            <div class="col-md-6" style="padding: 15px 10px 0px 0px;text-align: right;"
                                *ngIf="xpertsPageMode!=4">
                                <button (click)="showAttachement = true" class="btn btn-primary pull-right mr-1"
                                type="button">
                               <fa-icon [icon]="faPaperclip" ></fa-icon>&nbsp; {{'master.Employee.label.attach' | translate}}
                                <sup>
                                    <span *ngIf="FileList.length>0" class="badge badge-pill badge-info " style=" right: -16px;
                                        top: -13px;
                                        background: white;
                                        color: rebeccapurple;
                                        padding: 4px 6px;">{{FileList.length}}</span>

                                </sup>

                            </button>&nbsp;&nbsp;
                                <button class="btn btn-raised btn-raised-secondary pull-right mr-1" (click)="clear()"
                                    type="submit">
                                    <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                    }}
                                </button>&nbsp;
                                <button 

                                    class="btn btn-success pull-right mr-1" type="submit">
                                    <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                    translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 form-group mb-3">
                                <label>{{ 'client1.label.Registeron' | translate }} </label>
                                <input type="text" name="Registeron" [(ngModel)]="model.RegisteredOn"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" required
                                    #Registeron="ngModel" #dp="bsDatepicker" class="form-control requiredIn"
                                    bsDatepicker>
                                <i class="i-Calendar calenderStyle" (click)="dp.toggle()"
                                    [attr.aria-expanded]="dp.isOpen"></i>

                                <!-- <span *ngIf="formPage.submitted && !DOJ.valid" class="invalid-tooltip">
                                    {{ 'master.Employee.message.msg024' | translate }}
                                </span> -->
                                <span *ngIf="formPage.submitted && !Registeron.valid" class="invalid-tooltip">
                                   
                                    {{ 'client1.error.Registeron' | translate }}
                                 </span>
                            </div>

                            
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.salution' | translate }}</label>
                                <input class="form-control form-control-rounded" name="salaution" #salution="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.Salution">
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                            </div>


                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Firstname' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" name="First" #First="ngModel" 
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75"  [(ngModel)]="model.FirstName" required>
                                <span *ngIf="formPage.submitted && !First.valid" class="invalid-tooltip">
                                   
                                   {{ 'client1.error.Firstname' | translate }}
                                </span>
                            </div>


                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Middlename' | translate }}</label>
                                <input class="form-control form-control-rounded" name="Middle" #Middle="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75"   [(ngModel)]="model.MiddleName">
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Lastname' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" name="Last" #Last="ngModel" 
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75"   [(ngModel)]="model.LastName" required>
                                <span *ngIf="formPage.submitted && !Last.valid" class="invalid-tooltip">
                                    
                                    {{ 'client1.error.Lastname' | translate }}
                                 </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Fullname' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" name="Full" #Full="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75"    [(ngModel)]="model.FullName" required>
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                                <span *ngIf="formPage.submitted && !Full.valid" class="invalid-tooltip">
                                   
                                    {{ 'client1.error.Fullname' | translate }}
                                 </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Clientid' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" name="Client" #Client="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75"   [(ngModel)]="model.ClientID" required>
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                                <span *ngIf="formPage.submitted && !Client.valid" class="invalid-tooltip">
                                    
                                    {{ 'client1.error.Clientid' | translate }}
                                 </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.mr/mrs' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" required name="Mrs" #Mrs="ngModel" 
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.ClientPrefix">
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                                <span *ngIf="formPage.submitted && !Mrs.valid" class="invalid-tooltip">
                                    
                                    {{ 'client1.error.mr' | translate }}
                                 </span>
                            </div>

                            <div class="col-md-3 form-group mb-5">
                                <div class="form-check" style="margin-top: 25px;">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                                    name="accountposted" #accountposted="ngModel"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.IsAccountPosted">
                                    <label class="form-check-label" for="flexCheckDefault">
                                       {{ 'client1.label.accountposted' | translate }}
                                       
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="card text-left">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            {{ 'client1.subheading1' | translate }}
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-lg-12 pt-3">
                                    <div class="col-lg-4 mb-">
                                        <label for="name">{{ 'client1.label.spousename' | translate }}</label>
                                        <input class="form-control form-control-rounded" name="Name" #Name="ngModel"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75"   [(ngModel)]="model.SpouseName">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-lg-4 mb-3">
                                        <label for="name">{{ 'client1.label.spouseemail' | translate }}</label>
                                        <input class="form-control form-control-rounded requiredIn" name="SEmail" #SEmail="ngModel"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"  maxlength="75"  required [(ngModel)]="model.SpouseEmail">
                                        <div class="icondiv">
                                            <fa-icon [icon]="faat" class="icon"></fa-icon>
                                        </div>

                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                        <span *ngIf="formPage.submitted && !SEmail.valid" 
                                        class="invalid-tooltip">
                                        
                                      Please Enter the Email ID
                                    </span> 
                                    </div>

                                    <div class="col-lg-4 mb-3">
                                        <label for="name">{{ 'client1.label.spousephone' | translate }}</label>
                                        <input class="form-control form-control-rounded requiredIn" name="Phone" #Phone="ngModel"
                                        pattern="[0-9]+" maxlength="75" [(ngModel)]="model.SpousePhone" required>
                                        <div class="icondiv">
                                            <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                        </div>
                                        <span *ngIf="formPage.submitted && !Phone.valid" class="invalid-tooltip">
                                          Please Enter the Numeric
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <div class="card text-left">
                            <div class="card">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            {{ 'client1.subheading2' | translate }}
                                        </div>

                                    </div>
                                </div>
                                <div class="row col-lg-12 pt-3">
                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.addressline1' | translate }}</label>
                                        <input class="form-control form-control-rounded" name="Address1"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #Address1="ngModel" [(ngModel)]="model.PrimaryAdd1">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>

                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.addressline2' | translate }}</label>
                                        <input class="form-control form-control-rounded" name="address2"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75" #address2="ngModel" [(ngModel)]="model.PrimaryAdd2">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.addressline3' | translate }}</label>
                                        <input class="form-control form-control-rounded" name="address2"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #address2="ngModel" [(ngModel)]="model.PrimaryAdd3">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.city' | translate }}</label>
                                        <input class="form-control form-control-rounded" name="city" #city="ngModel"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75"  [(ngModel)]="model.PrimaryCity">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.State'| translate }}</label>
                                        <input class="form-control form-control-rounded" name="state" #state="ngModel"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.PrimaryState">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>
                                    <div class="col-lg-3 mb-3">
                                        <label for="name">{{ 'client1.label.Country'| translate }}</label>
                                        <input class="form-control form-control-rounded" name="Country"
                                        (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #Country="ngModel" [(ngModel)]="model.PrimaryCountry">
                                        <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                            {{ 'master.accounts.message.msg002' | translate }}
                                        </span> -->
                                    </div>
                             
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.Postalcode'| translate }}</label>
                                    <input class="form-control form-control-rounded requiredIn" name="post" #post="ngModel"
                                    pattern="[0-9]+" maxlength="75"  [(ngModel)]="model.PrimaryPostalCode" required>
                                    <span *ngIf="formPage.submitted && !post.valid" class="invalid-tooltip">
                                        Please Enter the Numeric
                                    </span>
                                    <!-- <span *ngIf="formPage.submitted && !Email12.valid"
                                    class="invalid-tooltip">
                                    Please Enter the Email ID
                                </span>  -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.Email' | translate }}</label>
                                    <input class="form-control form-control-rounded required requiredIn" name="Email12" #Email12="ngModel"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="75" required [(ngModel)]="model.PrimaryEmail">
                                    <div class="icondiv">
                                        <fa-icon [icon]="faat" class="icon"></fa-icon>
                                    </div>
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                    <span *ngIf="formPage.submitted && !Email12.valid"
                                    class="invalid-tooltip">
                                    Please Enter the Email ID
                                </span> 
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.Mobile'| translate }}</label>
                                    <input class="form-control form-control-rounded requiredIn" required name="Mobile" #Mobile="ngModel"
                                    pattern="[0-9]+" maxlength="75" [(ngModel)]="model.PrimaryMobile">
                                    <div class="icondiv">
                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                    </div>

                                    <span *ngIf="formPage.submitted && !Mobile.valid" class="invalid-tooltip">
                                        Please Enter a Numeric
                                    </span>
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.Fax'| translate }}</label>
                                    <input class="form-control form-control-rounded" name="fax" #fax="ngModel" 
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"  [(ngModel)]="model.PrimaryFax">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.homephone' | translate }}</label>
                                    <input class="form-control form-control-rounded requiredIn" required name="home" #home="ngModel"
                                    pattern="[0-9]+" maxlength="75" [(ngModel)]="model.PrimaryHomePhone">
                                    <div class="icondiv">
                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                    </div>
                                    <span *ngIf="formPage.submitted && !home.valid" class="invalid-tooltip">
                                       Please Enter the Numeric
                                    </span>
                                </div>

                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.WorkPhone' | translate }}</label>
                                    <input class="form-control form-control-rounded requiredIn" required name="work" #work="ngModel"
                                    pattern="[0-9]+"  maxlength="75"  [(ngModel)]="model.PrimaryWorkPhone">
                                    <div class="icondiv">
                                        <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                    </div>
                                    <span *ngIf="formPage.submitted && !work.valid" class="invalid-tooltip">
                                         Please Enter the Numeric
                                        </span>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mb-4">
                    <div class="card text-left">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-lg-6">
                                        {{ 'client1.subheading3' | translate }}
                                    </div>

                                </div>
                            </div>
                            <div class="row col-lg-12 pt-3">
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.addressline1' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="Address11"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #Address11="ngModel" [(ngModel)]="model.SecondaryAdd1">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>

                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.addressline2' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="address21"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #address21="ngModel" [(ngModel)]="model.SecondaryAdd2">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.addressline3' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="address3"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"  #address3="ngModel" [(ngModel)]="model.SecondaryAdd3">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.city' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="city1" #city1="ngModel"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.SecondaryCity">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.State' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="state1" #state1="ngModel"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"  [(ngModel)]="model.SecondaryState">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                                <div class="col-lg-3 mb-3">
                                    <label for="name">{{ 'client1.label.Country' | translate }}</label>
                                    <input class="form-control form-control-rounded" name="Country1"
                                    (keypress)="authService._restrictspecialchar($event)" maxlength="75"   #Country1="ngModel" [(ngModel)]="model.SecondaryCountry">
                                    <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                        {{ 'master.accounts.message.msg002' | translate }}
                                    </span> -->
                                </div>
                         
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Postalcode' | translate }}</label>
                                <input class="form-control form-control-rounded" name="post1" required #post1="ngModel"
                                pattern="[0-9]+" maxlength="75"  [(ngModel)]="model.SecondaryPostalCode">
                                <span *ngIf="formPage.submitted && !post1.valid" class="invalid-tooltip">
                                 Please Enter the Numeric
                                </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Email' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" name="Email" #Email="ngModel"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="75" required  [(ngModel)]="model.SecondaryEmail">
                                <div class="icondiv">
                                    <fa-icon [icon]="faat" class="icon"></fa-icon>
                                </div>
                                <span *ngIf="formPage.submitted && !Email.valid" class="invalid-tooltip">
                                        Please Enter the Email
                                </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Mobile' | translate }}</label>
                                <input class="form-control form-control-rounded requiredIn" required name="Mobile1" #Mobile1="ngModel"
                                pattern="[0-9]+" maxlength="75"  [(ngModel)]="model.SecondaryMobile">
                                <div class="icondiv">
                                    <fa-icon [icon]="faPhone" class="icon"></fa-icon>
                                </div>
                                <span *ngIf="formPage.submitted && !Mobile1.valid" class="invalid-tooltip">
                                   Please Enter the Numeric
                                </span>
                            </div>
                            <div class="col-lg-3 mb-3">
                                <label for="name">{{ 'client1.label.Fax' | translate }}</label>
                                <input class="form-control form-control-rounded" name="fax1" #fax1="ngModel"
                                (keypress)="authService._restrictspecialchar($event)" maxlength="75" [(ngModel)]="model.SecondaryFax">
                                <!-- <span *ngIf="formPage.submitted && !Code.valid" class="invalid-tooltip">
                                    {{ 'master.accounts.message.msg002' | translate }}
                                </span> -->
                            </div>
                           

                           

                        </div>
                    </div>
                </div>
            </div>

                <div class="card-footer" style="padding:10px 20px 10px 15px;">
                    <div class="row">
                        <div class="col-md-6 pl-4 mt-2">
                            <div class="row">

                                <label class="switch">
                                    <input name="ch" type="checkbox" [(ngModel)]="model.Status">
                                    <span class="slider round"></span>
                                </label>
                                <span *ngIf="model.Status==true">{{'Common.active' | translate }}</span>
                                <span *ngIf="model.Status==false">{{'Common.inActive' | translate }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" style="padding: 0px 10px 0px 0px; text-align: right;"
                            *ngIf="xpertsPageMode!=4">
                            <button class="btn btn-raised btn-raised-secondary pull-right mr-1" (click)="clear()"
                                type="submit">
                                <fa-icon [icon]="faEraser"></fa-icon>&nbsp;{{ 'Common.clear' | translate
                                }}
                            </button>&nbsp;
                            <button [disabled]="sumitChanges" [hidden]="xpertsPageMode==3"
                                class="btn btn-success pull-right mr-1" type="submit">
                                <fa-icon [icon]="faSave" style="color: #ece7e7;"></fa-icon>&nbsp;{{ commonSave |
                                translate }}
                            </button>

                        </div>
                    </div>
                </div>
        </div>
        </form>
    </div>
</div>
<div class="lds-roller" *ngIf="ProgressSpinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
</div>
</div>