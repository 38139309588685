<div class="breadcrumb">
  <h1>Modals</h1>
  <ul>
    <li><a href="">UI Kits</a></li>
    <li>Modals</li>
  </ul>
</div>

<div class="separator-breadcrumb border-top"></div>

<div class="row">
  <div class="col-md-6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="card-title">
          Basic Modal
        </div>
        <button class="btn btn-outline-primary btn-rounded" (click)="open(modalBasic)">Launch demo modal</button>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card mb-3">
      <div class="card-body">
        <div class="card-title">
          Confirm Modal
        </div>
        <button class="btn btn-outline-primary btn-rounded" (click)="confirm(modalConfirm)">Delete</button>
        <p class="mb-0 mt-3">{{confirmResut}}</p>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <div class="card mb-3">
      <div class="card-body">
          <div class="card-title">
            Large Content Modal
          </div>
          <button class="btn btn-outline-primary btn-rounded" (click)="open(modalLong)">Launch Modal</button>
        </div>
      </div>
  </div>
  <div class="col-md-6">
      <div class="card mb-3">
        <div class="card-body">
            <div class="card-title">
              Small Modal
            </div>
            <button class="btn btn-outline-primary btn-rounded" (click)="openSmall(modalSmall)">Launch Modal</button>
          </div>
        </div>
    </div>
</div>


<ng-template #modalBasic let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Modal Basic</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="picker1">Date picker</label>
        <div class="input-group">
          <input id="picker1" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-secondary" (click)="dp.toggle()" type="button">
              <i class="icon-regular i-Calendar-4"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-rounded" (click)="modal.close('Save click')">Save</button>
  </div>
</ng-template>

<ng-template #modalConfirm let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Profile deletion</h4>
      <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p><strong>Are you sure you want to delete <span class="text-primary">"John Doe"</span> profile?</strong></p>
      <p>All information associated to this user profile will be permanently deleted.
      <span class="text-danger">This operation can not be undone.</span>
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary btn-rounded" (click)="modal.dismiss('cancel')">Cancel</button>
      <button type="button" ngbAutofocus class="btn btn-danger btn-rounded" (click)="modal.close('Ok')">Ok</button>
    </div>
</ng-template>

<ng-template #modalSmall let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Small Modal</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Lorem ipsum dolor sit amet consectetur.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark btn-rounded" (click)="modal.close('ok')">Ok</button>
    </div>
</ng-template>

<ng-template #modalLong let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Modal With large content</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur blanditiis ipsam voluptate eveniet. Ipsum, vero similique?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark btn-rounded" (click)="modal.close('ok')">Ok</button>
    </div>
  </ng-template>