<div>
  <div [ngClass]="{ open: navService.sidebarState.sidenavOpen }" class="sidebar-left rtl-ps-none" [perfectScrollbar]="{ suppressScrollX: true }">
    <div class="logo">
      <img src="./assets/images/logo.png" alt="" />
    </div>
    <ul class="navigation-left">
      <li [ngClass]="{ active: item.active }" (click)="onClickChangeActiveFlag(item)" class="nav-item lvl1" *ngFor="let item of nav">
        <div class="nav-item-hold" routerLink="{{ item.state }}" (click)="closeChildNav()" *ngIf="!item.disabled && item.type === 'link'">
          <i [class]="'nav-icon ' + item.icon"></i>
          <span class="nav-text">{{ item.name }}</span>
        </div>
        <div class="nav-item-hold" (mouseenter)="selectItem(item)" *ngIf="!item.disabled && item.type === 'dropDown'">
          <i [class]="'nav-icon ' + item.icon"></i>
          <span class="nav-text">{{ item.name }}</span>
        </div>
        <div class="nav-item-hold" *ngIf="!item.disabled && item.type === 'extLink'">
          <a [href]="item.state" target="_blank">
            <i [class]="'nav-icon ' + item.icon"></i>
            <span class="nav-text">{{ item.name }}</span>
          </a>
        </div>

        <div class="triangle" *ngIf="!item.disabled"></div>
      </li>
    </ul>
  </div>

  <div [ngClass]="{ open: navService.sidebarState.childnavOpen }" class="sidebar-left-secondary rtl-ps-none" [perfectScrollbar]="{ suppressScrollX: true }"
    (mouseleave)="closeChildNav()">
    <header>
      <h6>Apps</h6>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
    </header>
    <ng-container *ngTemplateOutlet="menuTemplate; context: { parentItem: navService.selectedItem }"></ng-container>
  </div>

  <ng-template #menuTemplate let-parentItem="parentItem">
    <ul class="childNav" appDropdown>
      <li *ngFor="let item of parentItem?.sub" appDropdownLink class="nav-item" (click)="selectSideManu(item)">
        <a *ngIf="item.type === 'link'" class="" routerLinkActive="open">
          <i *ngIf="item.icon" [class]="'nav-icon ' + item.icon"></i>
          <span class="item-name lvl1">{{ item.name }}</span> <span></span>
        </a>

        <div *ngIf="item.type === 'dropDown'">
          <a appDropdownToggle [ngClass]="{ open: item.active }" routerLinkActive="open">
            <i *ngIf="item.icon" [class]="'nav-icon ' + item.icon"></i>
            <span class="item-name">{{ item.name }}1</span>
            <i class="dd-arrow i-Arrow-Down"></i>
          </a>
          <ng-container *ngTemplateOutlet="menuTemplate; context: { parentItem: item }"></ng-container>
        </div>
      </li>
    </ul>
  </ng-template>

  <div [ngClass]="{ open: navService.sidebarState.childnavOpen }" class="sidebar-overlay" (click)="closeChildNav()"></div>
</div>

<!-- <div *ngIf="!sideMenuOption">
  <div [ngClass]="{ open: navService.sidebarState.sidenavOpen }" class="sidebar-left rtl-ps-none" [perfectScrollbar]="{ suppressScrollX: true }">
    <div class="logo">
      <img src="./assets/images/logo.png" alt="" />
    </div>
    <ul class="navigation-left">
      <li *ngFor="let menuList of menuGroupList" class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
        <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <i class="{{menuList.MenuGroupIcon}}"></i> {{menuList.MenuGroupLabel}}</a>
        <ul class="nav-dropdown-items" style="padding: 0;">
          <li class="nav-item" *ngFor="let menu of menuList.MenuList">
            <a class="nav-link" routerLinkActive="active" (click)="goto(menu, menuList.MenuGroupName)" [routerLink]="['/' + menu.Group + '/' + menu.Path]">
              <i class='{{menu.Icon}}'></i>{{menu.MenuLabel}}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="!sideMenuOption">
  <div class="">
    <div class="sidebar">
      <nav class="sidebar-nav">
        <ul class="nav">
          <li class="divider" *ngIf="isHRrole == false"></li>
          <span *ngIf="menuGroupList.length > 0 && ismenuType==1">
            <span *ngFor="let menuList of menuGroupList">
              <li class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
                <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
                  <i class="{{menuList.MenuGroupIcon}}"></i> {{menuList.MenuGroupLabel}}</a>
                <ul class="nav-dropdown-items">
                  <li class="nav-item" *ngFor="let menu of menuList.MenuList">
                    <a class="nav-link" routerLinkActive="active" (click)="goto(menu, menuList.MenuGroupName)" [routerLink]="['/' + menu.Group + '/' + menu.Path]">
                      <i class='{{menu.Icon}}'></i>{{menu.MenuLabel}}</a>
                  </li>
                </ul>
              </li>
            </span>
          </span>

          <ng-container *ngFor="let menuList of menuGroupList">
            <li *ngIf="menuList.MenuGroupName !='Control Center'" class="nav-item nav-dropdown" routerLinkActive="open" appNavDropdown>
              <a class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle style="font-size: 15px;">
                <i class="{{menuList.MenuGroupIcon}}"></i> {{menuList.MenuGroupLabel}}
              </a>
              <ul class="nav-dropdown-items" appNavDropdown>
                <li class="nav-item" *ngFor="let menu of menuList.MenuList">
                  <a class="nav-link" routerLinkActive="active" (click)="goto(menu, menuList.MenuGroupName)" [routerLink]="['/' + menu.Group + '/' + menu.Path]"
                    style="color:#666666 !important">
                    <i class='{{menu.Icon}}'></i>{{menu.MenuLabel}}</a>
                </li>
              </ul>

            </li>
          </ng-container>

        </ul>
      </nav>
    </div>
  </div>

</div> -->