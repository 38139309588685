import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UUID } from 'angular2-uuid';
import { faSave,faEraser } from '@fortawesome/free-solid-svg-icons';
import { TitleCasePipe } from '@angular/common';
@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  icon: string = 'fa fa-save';
  commonSave: string = 'Common.save';
  faEraser=faEraser;
  faSave = faSave;
  model: any = {};
  screenTypeList: any = [];
  ddlCountry: any = [];
  ddlStateZone: any = [];
  xpertsPageMode: number;
  badgeStatus:string;
  ProgressSpinner:boolean
  constructor(private authService: AuthService, private toastr: ToastrService) {
    setTimeout(() => {
      let d = JSON.parse(sessionStorage.getItem('selectedMenu'));
   if (d.Insert == 'True') {

    
     this.pageMode(1);
   } else if (d.Insert == 'False') {

     this.pageMode(0);
   }
    }, 300);
   }

   newForm(){
    this.model= {
      "StateCode": "",
      "StateOUCode": null,
      "StateName": "",  
      "StateDesc": "",
      "Status":true,
    };
   // this.model.Status = 'INACTIVE';
    this.badgeStatus='New';   
  }

  ngOnInit() {
  //  this.model.Status = this.model.Status.toLowerCase();
  //   console.log("this,model",this.model.Status);
 
  
  }

  //determine page mode
  pageMode(mode){
    this.xpertsPageMode = mode;
    this.newForm();
   
  }
  

  back(){
    this.newForm();
  }
  clear(){
    
    this.newForm();
  }
  //post and edit function
  postForm(form: NgForm){   
   
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    var res = this.model.Status.toLowerCase();
    console.log("console====>",res);
    this.ProgressSpinner=true;
    this.model.Status=res;
    console.log("this,model===>",this.model.Status);
    this.authService.CrmPostJsonService(this.authService.masterApi,'PostState',this.model).subscribe(result => {
      this.ProgressSpinner=false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      console.log("this.model",this.model);
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222"){
          this.toastr.warning(result.Response._Description,"Warning");
          this.xpertsPageMode=1;
        }
        else {
          this.toastr.error(result.Response._Description, 'Error');
        } 
      }
    });
  }
  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "StateCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetState", req).subscribe(result => {
      this.ProgressSpinner = false;
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.State[0]);
          console.log("this.model12",this.model);
          
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.State[0]);
          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.State[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.State[0]);
           
          }
    }
  }
  });
    
      }

}
