<div class="">
  <div class="breadcrumb">
    <h1>Card eCommerce</h1>
    <ul>
      <li><a href="">UI Kits</a></li>
      <li>Card eCommerce</li>
    </ul>
  </div>

  <div class="separator-breadcrumb border-top"></div>

  <div class="row">
    <div class="col-lg-3">
      <div class="card card-ecommerce-1 mb-4">
        <div class="card-body text-center">
          <i class="i-Cloud mb-3"></i>
          <h5 class="text-primary">Cloud product one</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. In, voluptates?</p>
          <button class="btn btn-primary btn-rounded">Buy now</button>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="card card-ecommerce-2 o-hidden mb-4">
        <img class="card-img-top" src="./assets/images/photo-long-3.jpg" alt="">

        <div class="row">
          <div class="col">
            <h5 class="m-0 font-weight-bold text-muted">Cloud product one</h5>
            <p class="m-0">$30</p>
          </div>
          <div class="card-action">
            <i class="icon i-Add-Cart"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card card-ecommerce-3 o-hidden mb-4">
        <div class="d-flex">
          <div class="">
            <img class="card-img-left" src="./assets/images/photo-wide-1.jpg" alt="">
          </div>
          <div class="flex-grow-1 p-4">
            <h5 class="m-0 font-weight-bold text-muted">Produt featured</h5>
            <p class="m-0 text-small text-muted">By ABC Cafe</p>
            <p class="m-0">$40 <del>$55</del></p>
            <p class="text-muted mt-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
              Nesciunt placeat esse tempore debitis.</p>
            <div class="actions">
              <button class="btn btn-sm rounded-circle btn-icon btn-outline-primary">
                <i class="icon i-Add-Cart"></i>
              </button>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
  </div>

</div>