import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { Observable } from 'rxjs';
import { delay, map } from "rxjs/operators";
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { environment } from '../../../environments/environment';
import { ToastrService, ToastrConfig } from 'ngx-toastr';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  
  authenticated = true;
  public apiUrl: string;
  public baseUrl: any = environment.serviceURL;
  public apiService: any = environment.serviceURL + "/api/";
  public masterApi: string = this.apiService + "Master/";
public metaProject:string = this.apiService +"MagicCraft/";

  // public purchaseApi: string = this.apiService + "Purchase/";
  public adminApi: string = this.apiService + "admin/";
  public EiUtils: string = this.apiService + "EiUtils/";
  public transApi: string = this.apiService + "/api/Transaction/";
  public EnquiryApi: string = this.apiService + "Enquiry/";
  public SalesApi: string = this.apiService + "Sales/";
  public accountApi: string = this.apiService + "Accounts/";
  public CRMApi: string = this.apiService + "CRM/";
  public dasApi: string = this.apiService + "DashBoard/";
 
  public assetApi: string = this.apiService + "Asset/";
  
  //created by Azhagu
  public RetainerApi: string = this.apiService + "Retainer/";
  public CaseApi: string = this.apiService + "Case/"
  maxPageIndex: number;
  currentIndexFrom: number;
  currentIndexTo: number;

  constructor(
    private store: LocalStoreService,
    private toastr: ToastrService,
    private http: Http,
    private router: Router
  ) {
    this.checkAuth();
  }

  getToken() {
    let myHeaders = new Headers();
    if (sessionStorage.getItem("currentUser") !== null) {
      let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (objUser.Token.Token) {
        myHeaders.append('Authorization', objUser.Token.Token);
      } else {
        myHeaders.append('Authorization', "");
      }
    }
    else {
      myHeaders.append('Authorization', "");
    }
    return new RequestOptions({ headers: myHeaders });
  }

  Post(data: any, url): Observable<any> {
    let objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.apiUrl = this.apiService + url;
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  GetCCDropDownList(filterRef: string) {
    this.apiUrl = this.adminApi + "GetCrmCodes?FilterRef=" + filterRef + "";
    let options = this.getToken();
    return this.GetValueWithoutToastr(this.apiUrl, options);

}
PostState(data: any) {
  this.apiUrl = this.masterApi + "PostState";
  let options = this.getToken();
  return this.PostValueJSON(this.apiUrl, data, options);
}
PostFileUpload(apiurl, mtd, data: any) {
  this.apiUrl = apiurl + mtd;
  let options = this.getToken();
  return this.PostValueJSON(this.apiUrl, data, options);
}

GetState(data: any) {
  this.apiUrl = this.masterApi + "GetState";
  let options = this.getToken();
  return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
}
  Get(monthID: any, url) {
    this.apiUrl = this.apiService + url + "?RoleGUID=" + monthID + "";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl, options);
  }
  Getdropdown(data: any, url) {
    this.apiUrl = this.apiService + url + '?' + this.urlParams(data);
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl, options);
  }
  GetCountry(data: any) {
    this.apiUrl = this.masterApi + "GetCountry";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetNotification(data: any) {
    this.apiUrl = this.metaProject + "GetNotificationList";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetSendnNotification(data: any) {
    this.apiUrl = this.metaProject + "GetSendNotificationList";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetMetaProject(data: any) {
    this.apiUrl = this.metaProject + "GetMetaProject";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetMetaPage(data: any) {
    this.apiUrl = this.metaProject + "GetMetaPage";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetMetaField(data: any) {
    this.apiUrl = this.metaProject + "GetMetaField";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  GetMetaType(data: any) {
    this.apiUrl = this.metaProject + "GetMetaType";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  PostCountry(data: any) {
    this.apiUrl = this.masterApi + "PostCountry";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  PostXpertsPage(data: any) {
    this.apiUrl = this.apiService + "admin/PostXpertsPage";

    let options = this.getToken();
    return this.PostValueWithoutToastr(this.apiUrl, data, options);
  }
  //Magic Craft pages 
  PostMetaProject(data: any) {
    this.apiUrl = this.metaProject + "PostMetaProject";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }

  PostSendNotification(data: any) {
    this.apiUrl = this.metaProject + "PostSendNotification";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  PostNotification(data: any) {
    this.apiUrl = this.metaProject + "PostNotification";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  PostMetaPage(data: any) {
    this.apiUrl = this.metaProject + "PostMetaPage";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  PostMetaField(data: any) {
    this.apiUrl = this.metaProject + "PostMetaField";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }
  PostMetaType(data: any) {
    this.apiUrl = this.metaProject + "PostMetaType";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }

  PostXpertsResult(data: any) {
    this.apiUrl = this.apiService + "admin/PostXpertsResult";
    let options = this.getToken();
    return this.PostValueWithoutToastr(this.apiUrl, data, options);
  }

  PostValueWithoutToastr(url: string, data: any, options: any) {
    return this.http.post(url, data, options).pipe(map((response: Response) => {
      if (response.json().Response != null) {
        if (response.json().Response._code == "111" || response.json().Response._code == "333") {
          return response.json();
        }
        else if (response.json().Response._code == "555") {
          this.onLoggedout();
          return null;
        }
        else {
          //this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
          return response.json();
        }
      }
      else {
        this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
        return null;
      }
    }));
  }

  PostValueJSON(url: string, data: any, options: any) {
    return this.http.post(url, data, options).pipe(map((response: Response) => {
      if (response.json().Response != null) {
        if (response.json().Response._code == "111" || response.json().Response._code == "333" || response.json().Response._code == "222") {
          return response.json();
        } else {
          this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
          return response.json();
        }
      } else {
        this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
        return null;
      }
    }));
  }

  GetValueJSON(url: string, options: any) {
    return this.http.get(url, options).pipe(map((response: Response) => {
      if (response.json().Response != null) {
        if (response.json().Response._code == "111" || response.json().Response._code == "333") {
          return response.json();
        } else {
          //  this.toastr.error("00Oops!! unable to process your request, Please contact administrator.", 'Error');
          return response.json();
        }
      } else {
        //this.toastr.error("1Oops!! unable to process your request, Please contact administrator.", 'Error');
        return null;
      }
    }));
  }

  GetDropDownList(filterRef: string, field1: string = "") {
    if (field1 != "")
      this.apiUrl = this.apiService + "admin/GetDropdown?FilterRef=" + filterRef + "&Depedentfieldvalue=" + field1;
    else
      this.apiUrl = this.apiService + "admin/GetDropdown?FilterRef=" + filterRef;

    let options = this.getToken();
    return this.GetValueWithoutToastr(this.apiUrl, options);
  }

  GetValueWithoutToastr(url: string, options: any) {
    return this.http.get(url, options).pipe(map((response: Response) => {
      if (response.json().Response != null) {
        if (response.json().Response._code == "111" || response.json().Response._code == "333") {
          return response.json();
        }
        else if (response.json().Response._code == "555") {
          this.onLoggedout();
          return null;
        }
        else {
          //this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
          return response.json();
        }
      }
      else {
        this.toastr.error("Oops!! unable to process your request, Please contact administrator.", 'Error');
        return null;
      }
    }));
  }

  findPageIndexLength(totalCount, pageSize, pageIndex) {
    console.log(totalCount, pageSize, pageIndex);

    let maxIndex: any = totalCount / pageSize;
    console.log(maxIndex);

    this.maxPageIndex = Math.ceil(maxIndex);
    let from = (pageIndex * Number(pageSize)) + 1;
    this.currentIndexFrom = from - Number(pageSize)
    this.currentIndexTo = pageIndex * pageSize;

  }

  CrmPostJsonService(apiurl, mtd, data: any) {
    this.apiUrl = apiurl + mtd;
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }

  CrmGetJsonService(apiurl, mtd, data: any) {
    this.apiUrl = apiurl + mtd;
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }

  PostScreen(data: any) {
    this.apiUrl = this.adminApi + "PostScreen";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }

  GetScreen(data: any) {
    this.apiUrl = this.adminApi + "GetScreen";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }

  GetRole(data: any) {
    this.apiUrl = this.adminApi + "GetRole";
    let options = this.getToken();
    return this.GetValueJSON(this.apiUrl + '?' + this.urlParams(data), options);
  }
  PostRole(data: any) {
    this.apiUrl = this.adminApi + "PostRole";
    let options = this.getToken();
    return this.PostValueJSON(this.apiUrl, data, options);
  }

  //#endregion
  urlParams(request: object): string {
    return Object.keys(request).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(request[k])
    }).join('&');
  }

  onLoggedout() {
    this.logout().subscribe(result => {
      if (result === true) {
      } else {
      }
    });
    this.clearLocalStorage();
    this.router.navigate(['/login/login']);
  }

  logout(): Observable<boolean> { // clear token remove user from local storage to log user out        
    // this.apiUrl = this.adminApi + "GetSignout?";

    let options = this.getToken();
    return this.IsValidAuthorization(this.apiUrl, options);
  }

  clearLocalStorage() {
    sessionStorage.clear();
    // sessionStorage.removeItem('profileMenu');
    // sessionStorage.removeItem('selectedMenu');
    // sessionStorage.removeItem('MenuList');
    // sessionStorage.removeItem('currentUser');
    // sessionStorage.removeItem('UserDashBoardList');
  }

  IsValidAuthorization(url: string, options: any) {
    return this.http.get(url, options).pipe(map((response: Response) => {
      if (response.json().Response != null) {
        if (response.json().Response._code == "111") {
          return true;
        }
        else if (response.json().Response._code == "555") {
          this.onLoggedout();
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }));
  }

  YYMMDDtoDDMMYY(date, type) {
    console.log("date datedate",date);
    let currentdate = new Date(date);
  
    var day = currentdate.getDate();
    var month = currentdate.getMonth() + 1;
    var year = currentdate.getFullYear();
  
    if (type == 1) {
        return (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
    }
    if (type == 2) {
        return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }
  }

  formatDate(date) {
    if (date != null && date != '') {
      let d: any = new Date(date);

      if (d != 'Invalid Date') {
        var day = d.getDate();
        console.log(day)
        var month = d.getMonth() + 1;
        console.log(month)
        var year = d.getFullYear();
        console.log(year)

        return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
      } else {
        d = [];
        d = date.split('-');
        console.log(date)
        day = d[0];
        console.log(day)
        month = d[1];
        console.log(month)
        year = d[2];
        console.log(year)
        return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
      }
    } else {
      return date;
    }
  }

  usformatDate(date) {
    if (date != null && date != '') {
      date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      return  (month < 10 ? '0' : '') + month    + '-' + (day < 10 ? '0' : '') + day + '-' + year;
    } else {
      return date;
    }
  }

  public _restrictNumbers(event: any) {

    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) {

      } else {
        event.preventDefault();
      }
    }
  }
  public _numberWithDecimal(event: any) {

    const pattern = /^[0-9.]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) {

      } else {
        event.preventDefault();
      }
    }
  }
  //crated by azhagu
   AlphaNumeric(event) {

    var alphaNum = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets
    if (/[a-zA-Z0-9]/.test(alphaNum)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  public _restrictCharacters(event: any) {
    const pattern = /^[A-Za-z ]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) { }
      else
        event.preventDefault();
    }
  }
  public _alphaCharacters(event: any) {
    const pattern = /^[A-Za-z0-9 ]*$/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) { }
      else
        event.preventDefault();
    }
  }
//created by azhagu

  public _restrictspecialchar(event: any) {
    const pattern = /^[a-zA-Z0-9,./_ &+$-@]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) { }
      else
        event.preventDefault();
    }
  }

  public _restrictPasswordChar(event: any) {
    const pattern = /^[a-zA-Z0-9,./!@#$%^&]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) { }
      else
        event.preventDefault();
    }
  }

  public _restrictspecialcharguid(event: any) {
    const pattern = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      if (event.keyCode == 8 || event.keyCode == 9) { }
      else
        event.preventDefault();
    }
  }


  checkAuth() {
    // this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return of({});
  }

  signin(credentials) {
    this.authenticated = true;
    this.store.setItem("demo_login_status", true);
    return of({}).pipe(delay(1500));
  }
  signout() {
    this.authenticated = false;
    this.store.setItem("demo_login_status", false);
    this.router.navigateByUrl("/sessions/signin");
  }
}
