import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityComponent } from './activity/activity.component';
import { BillingCatagoryComponent } from './billing-catagory/billing-catagory.component';
import { BillingRateComponent } from './billing-rate/billing-rate.component';
import { CalenderComponent } from './calender/calender.component';
import { CallLogComponent } from './call-log/call-log.component';
import { CaseTypeComponent } from './case-type/case-type.component';
import { CityComponent } from './city/city.component';
import { ClientComponent } from './client/client.component';
import { ContactsComponent } from './contacts/contacts.component';
import { CountryComponent } from './country/country.component';
import { CountyComponent } from './county/county.component';
import { EmployeeComponent } from './employee/employee.component';
import { EventTypeComponent } from './event-type/event-type.component';
import { RefrsComponent } from './refrs/refrs.component';
import { SourceComponent } from './source/source.component';
import { StateComponent } from './state/state.component';

const routes: Routes = [
  { path: 'Contact', component: ContactsComponent },
  { path: 'Country', component: CountryComponent },
  { path: 'States', component: StateComponent },
  { path: 'City', component: CityComponent },
  { path: 'CaseType', component: CaseTypeComponent },
  { path: 'Employee', component: EmployeeComponent },
  { path: 'Client', component: ClientComponent },
  { path: 'CallLog', component: CallLogComponent },
  { path: 'EventType', component: EventTypeComponent },
  { path: 'Source', component: SourceComponent },
  { path: 'Reference', component: RefrsComponent },
  { path: 'Activity', component: ActivityComponent },
  { path: 'BillingCatagory', component: BillingCatagoryComponent },
  { path: 'BillingRate', component: BillingRateComponent },
  { path: 'Calender', component: CalenderComponent },
  { path: 'County', component: CountyComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
