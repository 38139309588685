import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faSave, faEraser, faTrash, faEdit, faPaperclip, faPrint, faPhone, faAt, faClock, faUsd, faTurkishLiraSign } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-call-log',
  templateUrl: './call-log.component.html',
  styleUrls: ['./call-log.component.scss']
})
export class CallLogComponent implements OnInit {
  model:any={};
  badgeStatus:any;
  icon: string = "fa fa-save";
  commonSave: string = 'Common.save';
  faclocko = faClock;
  fausd = faUsd;
  faat = faAt;
  faSave = faSave;
  faPhone=faPhone;
  faAt=faAt;
  faPaperclip = faPaperclip;
  faPrint = faPrint;
  faEraser = faEraser;
  calllog:any=[];
  Calltype:any=[];
  ProgressSpinner: boolean = false;
  xpertsPageMode: number;
  Date = new Date();

  constructor(private TranslateService: TranslateService,
    private authService: AuthService,
    private toastr: ToastrService) { }
  
  newForm(){
  this.model= {
  "CallLogCode": "",
  "CallLogDate": this.Date,
  "CallDesc":"" ,
  "CallLogBy":"" ,
  "FirstName":"" ,
  "MiddleName": "",
  "LastName":"" ,
  "FullName":"" ,
  "ContactNumber": "",
  "Gmail": "",
  "CallType":"" ,
  "Salutation": "",
  "Status": ""
    }; 
    this.badgeStatus='New';   
  }
  ngOnInit(): void {
    this.loadDropDown();
    let data = new Date()
    data.setHours(0);
    data.setMinutes(0);
    data.setSeconds(0);
    this.Date = new Date(data)
  }
  loadDropDown() {

    this.authService.GetDropDownList('CallLogBy').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.calllog = result.Dropdownlist
        }
      }
    })


    this.authService.GetDropDownList('CallType').subscribe(result => {
      if (result !== null) {
        if (result.Response._code == "111") {
          this.Calltype = result.Dropdownlist
        }
      }
    })
  }
 
  postForm(form: NgForm) {
    console.log("this.model",this.model);
    this.commonSave = 'Common.process';
    this.icon = "fa fa-spinner fa-spin";
    this.model.CallLogDate = this.authService.formatDate(this.model.CallLogDate);
    this.ProgressSpinner = true;
    this.authService.CrmPostJsonService(this.authService.masterApi, 'PostCalllog', this.model).subscribe(result => {
      this.ProgressSpinner = false;
      this.commonSave = 'Common.save';
      this.icon = "fa fa-save";
      if (result !== null) {
        if (result.Response._code == "111") {
          this.toastr.success(result.Response._Description, "Success");
          form.resetForm();
        //  this.pageEditData();
          this.xpertsPageMode = 0;
        }
        else if (result.Response._code == "222") {
          this.toastr.warning(result.Response._Description, "Warning");
           this.xpertsPageMode = 1;
        }        
      }else {
        this.toastr.error(result.Response._Description, 'Error');
      }
    },(err)=>{
      this.ProgressSpinner=false;
    });
  }

  pageEditData(pageData) {//getting response from api in edit mode
    let req = { 
      "CalllogCode":pageData[0]
    };
   
    this.ProgressSpinner = true;
    this.authService.CrmGetJsonService(this.authService.masterApi, "GetCalllog", req).subscribe(result => {
      this.ProgressSpinner = false;
      
      if (result !== null) { 
        if (result.Response._code == "111") {
          this.model=Object.assign({},result.Callloglists);
          if(this.xpertsPageMode == 2){
            this.badgeStatus='Edit';
           this.model = Object.assign({},result.Callloglists[0]);

           this.model.CallLogDate = this.authService.formatDate(this.model.CallLogDate);
           console.log("this.date",this.model.CallLogDate)

          }else if(this.xpertsPageMode == 4){
            this.badgeStatus='View';
           this.model = Object.assign({},result.Callloglists[0]);
          }else if(this.xpertsPageMode == 3){
          //  this.badgeStatus='Copy';
            this.model = Object.assign({},result.Callloglists[0]);
           
          }
    }
  }
  });
    
      }
// toggle(){
//   if(this.model.Status==true){
//     this.model.Status == 'Active';
//   }else{
//     this.model.Status == "InActive"
//   }
// }
    //determines pagemode
    pageMode(mode) {
      this.xpertsPageMode = mode;
      this.newForm();
    }

    clear(){
      this.newForm();
    }


}
