import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  title = 'bootDash';

  constructor(
    private translate: TranslateService,
    public router: Router,
    private authService: AuthService
  ) {
    //, private userIdle: UserIdleService
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    let browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en/) ? browserLang : 'en');
    this.router.routerState.root.queryParams.subscribe(params => {
      console.log("paramsparamsparams", params);
      sessionStorage.setItem('Params', JSON.stringify(params));
    });
  }
}
