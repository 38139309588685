<div id="eisUploadRightSideBar" class="CRMsidenav" #crmRightBar>
    <div class="upload-content-header">
        <a href="javascript:void(0)" class="closebtn" (click)="UploadResponse(false)">&times;</a>
        <h6 class="text-center" style="font-weight: 700;">Attachment{{(PageFileList.length)?'s ('+PageFileList.length+')' :''}}
        </h6>
    </div>
    <div class="upload-content-list">
        <div style="margin-bottom: 0.5rem;background: white" *ngFor="let loop of PageFileList;let i=index">
            <div class="p-1 clearfix">
                <img src="assets/images/tr.png" style="cursor: pointer;    cursor: pointer;float: right;width: 16px;padding-top: 5px;" (click)="open();index=i">
                <div class="p-1" style="cursor: pointer;" (click)="openFile(i)">{{loop.FileName}}</div>
                <div class="text-muted font-xs">{{loop.Description}}</div>
            </div>
        </div>
        <div style="padding-top: 50px;" class="text-center" *ngIf="!PageFileList.length">
            No files selected!
        </div>
    </div>
    <div class="upload-content-form">
        <form #upload="ngForm" novalidate name="UploadFrm" (ngSubmit)="upload.form.valid && postForm(upload)">
            <div class="text-center">
                <div class="form-group">
                    <div class="form-inline">
                        <input type="file" class="form-control-file" [(ngModel)]="model.FileData" name="FileData" #uploadInput="ngModel" (change)="fileUpload($event)"
                            required>
                        <span class="mat-error" style="font-size: 14px" *ngIf="upload.submitted && !uploadInput.valid">
                            Please upload file
                        </span>
                    </div>
                    <div class="form-inline">
                    </div>
                </div>
            </div>
            <div class="text-center mt-5">
                <button class="btn btn-success  mr-1" type="submit">
                    <i class="fa fa-upload"></i>&nbsp;{{ 'Common.upload' | translate }}</button>
                <button class="btn btn-light  " type="button" (click)="clear(upload)">
                    <i class="fa fa-eraser"></i>&nbsp;{{ 'Common.clear' | translate }}</button>&nbsp;
            </div>
        </form>
    </div>
    <div class="lds-roller" *ngIf="ProgressSpinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" class=" animated fadeIn">
    <div class="modal-content">
        <div class="modal-header">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-6">
                        <span style="font-size: 24px;">
                            <strong>Confirmation?</strong>
                            </span>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: end;">
                        <div class="closeIcon">
                            <i class="i-Close-Window" aria-hidden="true" (click)="onClose()"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div class="row" style="margin-top: 10px;">
                <div class="col-lg-12 mb-3" style="text-align: center;">
                    <h5>Are You Sure Want To Delete Record ?</h5>
                </div>
                <div class="col-lg-6" style="text-align: center;">
                    <button class="btn btn-info px-4" (click)="delImg()">
                        <i class=""></i>OK</button>
                </div>
                <div class="col-lg-6" style="text-align: center;">
                    <button class="btn btn-dark" (click)="onClose()">
                        <i class="i-Close-Window"></i> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>