import { Component, OnInit, Output, EventEmitter, TemplateRef, ViewChild, Input, SimpleChange } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { NgForm } from '@angular/forms';
import { UUID } from 'angular2-uuid';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-attachment-popup',
  templateUrl: './attachment-popup.component.html',
  styleUrls: ['./attachment-popup.component.scss']
})
export class AttachmentPopupComponent implements OnInit {

  @ViewChild('content')
  private modalRef: TemplateRef<any>;
  @Output() uploaded = new EventEmitter<any>();
  @Input() InputData: any = [];
  showSideBar: boolean = false;
  pageData: any = {};
  ProgressSpinner: boolean = false;
  PageFileList: any = [];
  model: any = { 'FileData': "", 'Desc': "" };
  FileUploadData: any = { 'base64': "", 'name': "", 'size': "" };
  fileSizeLimit: number = 0;
  mb: number = 1048576;
  currentUser: any;
  imageData: any;
  index: any;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.pageData = JSON.parse(sessionStorage.getItem('selectedMenu'));
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  ngOnInit(): void {
    document.getElementById("eisUploadRightSideBar").style.width = "20%";
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    this.PageFileList = changes['InputData'].currentValue;
  }

  calculateSize(val) {
    if (val == undefined || typeof (val) != 'number')
      return 1048576;
    return val * this.mb;
  }

  open() {
    this.modalService.open(this.modalRef, { size: 'md', backdrop: 'static', keyboard: false });
  }

  UploadResponse(e) {
    this.uploaded.emit({ 'show': e, 'data': this.PageFileList });
  }

  onClose() {
    this.modalService.dismissAll();
  }

  postForm(form: any) {
    this.ProgressSpinner = true;
    if (this.imageData.target.files[0] != undefined) {
      this.FileUploadData = this.imageData.target.files[0];
      const formData: FormData = new FormData();
      formData.append("File", this.FileUploadData);
      formData.append("fileMode", "post");
      this.authService.PostFileUpload(this.authService.adminApi, 'PostSingleFileUpload', formData).subscribe(result => {
        if (result !== null) {
          if (result.Response._code == "111") {
            this.ProgressSpinner = false;
            var rooturl = this.authService.baseUrl;

            console.log("rooturl===>", rooturl)
            console.log("rooturl2222===>", result.RelativePath)
            var imurl = rooturl + '/' + result.RelativePath.replace(/\\/g, "/");
            console.log("rooturl3333===>", imurl)
            this.PageFileList.push({ 'FileGUID': result.FileGUID, 'FileName': result.FileName, 'Description': result.Description, 'FilePath': result.RelativePath });
            form.resetForm();
          }
          else {
            this.toastr.error(result.Response._Description, 'Error');
          }
        }
      });
    }
  }

  base64(file) {
    var myReader: FileReader = new FileReader();
    var _this = this;
    myReader.onloadend = function (loadEvent: any) {
      _this.FileUploadData.base64 = loadEvent.target.result.split(",")[1];
      _this.FileUploadData.name = file.name;
      _this.FileUploadData.size = file.size;

    };
    if (file)
      myReader.readAsDataURL(file);
  }

  fileUpload(e) {
    this.imageData = e
  }

  openFile(e) {
    let url = this.authService.baseUrl + '/' + this.PageFileList[e].FilePath + '/' + this.PageFileList[e].FileGUID + '_' + this.PageFileList[e].FileName;
    console.log(url, 'url');
    window.open(url, '_blank');
  }

  clear(frm: NgForm) {
    frm.resetForm();
  }

  delImg() {
    this.PageFileList.splice(this.index, 1);
    this.onClose();
  }

  status(e) {
    console.log(e)
  }

}
