import { Component, OnInit, HostListener, ViewChildren, QueryList } from '@angular/core';
import {
  NavigationService,
  IMenuItem,
  IChildItem
} from '../../../../services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { filter } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { environment } from '../../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-sidebar-large',
  templateUrl: './sidebar-large.component.html',
  styleUrls: ['./sidebar-large.component.scss']
})
export class SidebarLargeComponent implements OnInit {
  selectedItem: IMenuItem;
  nav: IMenuItem[];
  @ViewChildren(PerfectScrollbarDirective) psContainers: QueryList<PerfectScrollbarDirective>;
  psContainerSecSidebar: PerfectScrollbarDirective;
  sideMenuOption: boolean = false;
  menuGroupList: any = [];
  objResult: any;
  ActiveMenuGroupID: any;
  menuList: any;
  isHRrole: any;

  constructor(
    public router: Router,
    private authenticationService: AuthService,
    public navService: NavigationService
  ) {
    setTimeout(() => {
      this.psContainerSecSidebar = this.psContainers.toArray()[1];
    });
  }

  ngOnInit() {
    this.updateSidebar();
    // CLOSE SIDENAV ON ROUTE CHANGE
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(routeChange => {
        this.closeChildNav();
        if (Utils.isMobile()) {
          this.navService.sidebarState.sidenavOpen = false;
        }
      });

    this.navService.menuItems$.subscribe(items => {
      this.nav = items;
      this.setActiveFlag();
    });

    setTimeout(() => {
      // new side menu
      this.sideMenuOption = environment.insideSideMenu;
      var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
      // if (sessionStorage.getItem('Rl' + objUser.RoleGUID) != null) {
      //   let MenuGroupList = sessionStorage.getItem('Rl' + objUser.RoleGUID)
      //   this.menuGroupList = JSON.parse(MenuGroupList);
      //   this.ActiveMenuGroupID = this.menuGroupList[0].MenuGroupID;
      // } else {
      this.authenticationService.Get(objUser.RoleGUID, 'admin/getRoleList').subscribe(result => {
        if (result !== null) {
          this.objResult = result;
          if (this.objResult.MenuGroupList !== null) {
            if (result.RoleName.toLowerCase() == 'employee') {
              this.menuList = this.objResult.MenuGroupList[0].MenuList;
            }
            else {
              if (this.isHRrole) {
                this.menuGroupList = result.MenuGroupList;
                this.ActiveMenuGroupID = this.menuGroupList[0].MenuGroupID;
              }
            }
            sessionStorage.setItem('Rl' + objUser.RoleGUID, JSON.stringify(this.menuGroupList));
          }
        }
      });
      // }
    }, 1000);
  }

  selectSideManu(item) {
    console.log('sideMenu', item);
    var objUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (objUser != null) {
      if (item !== null) {
        // item["Module"] = moduleName;
        setTimeout(() => {
          sessionStorage.setItem('selectedMenu', JSON.stringify(item));
          sessionStorage.setItem('Rl' + objUser.RoleGUID, JSON.stringify(item));
          this.router.navigate([item.state])
        }, 500);
        // this.url = sessionStorage.getItem('selectedMenu');
      }
    }
  }

  selectItem(item) {
    this.navService.sidebarState.childnavOpen = true;
    this.navService.selectedItem = item;
    this.setActiveMainItem(item);

    // Scroll to top secondary sidebar
    setTimeout(() => {
      this.psContainerSecSidebar.update();
      this.psContainerSecSidebar.scrollToTop(0, 400);
    });
  }
  closeChildNav() {
    this.navService.sidebarState.childnavOpen = false;
    this.setActiveFlag();
  }

  onClickChangeActiveFlag(item) {
    this.setActiveMainItem(item);
  }
  setActiveMainItem(item) {
    this.nav.forEach(i => {
      i.active = false;
    });
    item.active = true;
  }

  setActiveFlag() {
    if (window && window.location) {
      const activeRoute = window.location.hash || window.location.pathname;
      this.nav.forEach(item => {
        item.active = false;
        if (activeRoute.indexOf(item.state) !== -1) {
          this.navService.selectedItem = item;
          item.active = true;
        }
        if (item.sub) {
          item.sub.forEach(subItem => {
            subItem.active = false;
            if (activeRoute.indexOf(subItem.state) !== -1) {
              this.navService.selectedItem = item;
              item.active = true;
            }
            if (subItem.sub) {
              subItem.sub.forEach(subChildItem => {
                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                  this.navService.selectedItem = item;
                  item.active = true;
                  subItem.active = true;
                }
              });
            }
          });
        }
      });
    }
  }

  updateSidebar() {
    if (Utils.isMobile()) {
      this.navService.sidebarState.sidenavOpen = false;
      this.navService.sidebarState.childnavOpen = false;
    } else {
      this.navService.sidebarState.sidenavOpen = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSidebar();
  }
}
