import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ddIndexValue'
})
export class DdIndexValuePipe implements PipeTransform {

  transform(value: any, args?: any, type?: string): any {    

    if (value == undefined || value == '')
      return null;
    if (args == undefined || args == '')
      return null;    
    if (type != undefined && type == 'Items') {      
      let i = args.findIndex(x => x.ItemGUID == value);
      return (i>-1)?args[i].Name:'Undefiend';
    } else {
      let i = args.findIndex(x => x.ID == value);
      return (i>-1)?args[i].Value:'Undefiend';      
    }
  }

}
