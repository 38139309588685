// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //Fire base Configuration
  firebase:{
    apiKey:"AIzaSyAIiww-D3nf0g4L7Lz5Sam0mJ3zD1coRC8",
      messagingSenderId: "589639789584",
      projectId: "pushnotification-ade1d",
      appId:"1:589639789584:android:ceda162ad83ac9376d6e95"


  },

  // serviceURL : "http://eiserpcoreapi.betaapps.in",
  configFile: "./assets/config/config.json",
  serviceURL: 'https://magiccraftapi.betaapps.in', //https://localhost:44349/
  insideSideMenu: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
