<!-- <ng-template #content let-c="close" let-d="dismiss" class=" animated fadeIn"></ng-template> -->

<div class="modal-content">
    <!-- <div class="modal-header" style="padding:0px">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-md-6">
                    <i style="font-size: 20px;" class="i-Telephone"></i>&nbsp; -->
                    <!-- <span style="font-size: 24px;">&nbsp;&nbsp; <strong>Confirmation</strong>
                        &nbsp;</span>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <div class="closeIcon">
                        <i class="i-Close-Window" aria-hidden="true"  type="button" (click)="onClose()"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>  --> <div class="modal-header">
        <div class="col-lg-12">
            <div class="row" style="position: relative;">
                <div class="col-md-6">                      
                    <span style="font-size: 24px;"> <strong>Confirmation</strong>
                        </span>
                </div>
                <div class="col-md-6"></div>
                <!-- <div class="col-sm-6 col-md-6 col-lg-6" style="text-align: end;"> -->
                    <!-- <div class="closeIcon"> -->
                        <span class="clsBtn">
                            <i class="i-Close-Window" aria-hidden="true" style="font-size: 24px;
                            color: white;
                            background: red;
                            border-radius: 50px;
                            border: none;" (click)="onClose()"></i>
                        </span>                            
                    <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 mb-3" style="text-align:center;font-size: 20px;">Are you sure want to delete Record?</div>
            <div class="col-lg-6" style="text-align: center;">
                <button type="submit" class="btn btn-success px-4" (click)="confirmDialog()">
                    <fa-icon [icon]="faCheck" style="margin-right: 5px;"></fa-icon>OK</button>
            </div>                
            <div class="col-lg-6" style="text-align: center;">
                <button class="btn btn-dark" type="button" (click)="confirmORCancel('1')"><i class="i-Close-Window"></i> Cancel</button>
            </div>
            
        </div>
    </div>
    <!-- <div class="modal-body">
        <div class="row">
            <div class="col-lg-12 mb-3" style="text-align: center; font-size:20px">
                <h5 >Are You Sure Want To Delete Record ?</h5>
            </div>
            <div class="col-lg-6" style="text-align: center;">
                <button class="btn btn-success px-4" (click)="confirm()"><i class=""></i>OK</button>
            </div>
            <div class="col-lg-6" style="text-align: center;">
                <button class="btn btn-dark" (click)="onClose()"><i class="i-Close-Window"></i> Cancel</button>

            </div>
        </div>
    </div> -->
</div>